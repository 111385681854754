<template>
    <FAQQuestion id="spam">
        <template #question>
            I'm not seeing emails from Gravitii.care.  How can I find and prevent emails from going to SPAM or Junkmail?
        </template>
        <div>
            <div class="mail-app-tabs">
                <div :class="mailApp=='gmail' ? 'selected' : ''" @click="mailApp='gmail'">Gmail</div>
                <div :class="mailApp=='outlook' ? 'selected' : ''" @click="mailApp='outlook'">Outlook</div>
                <div :class="mailApp=='icloud' ? 'selected' : ''" @click="mailApp='icloud'">iCloud</div>
                <div :class="mailApp=='yahoo' ? 'selected' : ''" @click="mailApp='yahoo'">Yahoo</div>
                <div :class="mailApp=='proton' ? 'selected' : ''" @click="mailApp='proton'">Proton Mail</div>
            </div>
            <div v-if="mailApp=='gmail'" id="gmail">
                <h2>Gmail</h2>
                <h3>Remove an email from Spam</h3>
                <h3>Computer</h3>
                <ol>
                    <li>On your computer, open <a target="_blank" href="https://mail.google.com/">Gmail</a>.</li>
                    <li>In the main menu, on the left, click More and then Spam.</li>
                    <li>Check the box next to the email that you want to remove.</li>
                    <li>At the top, click Not spam.</li>
                </ol>
                <h3>Android</h3>
                <ol>
                    <li>On your Android device, open the Gmail app.</li>
                    <li>At the top left, tap Menu <img src="@/assets/faq/menu.png" /> and then Spam.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the top right, tap More <img src="@/assets/faq/more.png" /> and then Report not spam.</li>
                </ol>
                <h3>iPhone/iPad</h3>
                <ol>
                    <li>On your iPhone or iPad, open the Gmail app.</li>
                    <li>At the top left, tap Menu <img src="@/assets/faq/menu.png" /> and then Spam.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the top right, tap More <img src="@/assets/faq/more-h.png" /> and then Report not spam.</li>
                </ol>

                <h3>Prevent emails from going to Spam</h3>
                <p>
                    Marking the email as not spam should prevent future emails from going to the spam folder. You can also add <a href="Javascript:void(0)" disabled>no-reply@gravitii.care</a> to
                    your <a href="https://contacts.google.com/" target="_blank">Google Contacts</a> or <a target="_blank" href="https://support.google.com/mail/answer/6579">create a filter</a> to make sure Gravitii emails do not go to spam in the future.
                </p>
            </div>
            <div v-if="mailApp=='outlook'" id="outlook">
                <h2>Outlook</h2>
                <h3>Remove an email from Spam</h3>
                <h3>Computer (Web)</h3>
                <ol>
                    <li>On your computer, open <a target="_blank" href="https://outlook.live.com/">Outlook</a>.</li>
                    <li>In the main menu, on the left, click Junk Email.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the top right of the email, tap More  <img src="@/assets/faq/more-h.png" />, then Report, then Not junk.</li>
                    <li>Click OK to the "Never send future messages from no-reply@gravitii.care to the Junk folder" popup.</li>
                </ol>
                <h3>Computer (New App)</h3>
                <ol>
                    <li>On your computer, open the Outlook (new) app.</li>
                    <li>In the main menu, on the left, click Junk Email.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the top right of the email, tap More <img src="@/assets/faq/more-h.png" />, then Report, then Not junk.</li>
                </ol>
                <h3>Computer (App)</h3>
                <ol>
                    <li>On your computer, open the Outlook app.</li>
                    <li>In the main menu, on the left, click Junk Email.</li>
                    <li>Right click on the email you want to remove.</li>
                    <li>Hover over Junk and click Not Junk.</li>
                    <li>In the "Mark as Not Junk" popup, check "always trust email from..." and click OK.</li>
                </ol>
                <h3>Android</h3>
                <ol>
                    <li>On your Android device, open the Outlook app.</li>
                    <li>At the top left, tap the Outlook icon and then Junk,</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the top right, tap More <img src="@/assets/faq/more.png" /> and then Not junk.</li>
                </ol>
                <h3>iPhone/iPad</h3>
                <ol>
                    <li>On your iPhone or iPad, open the Outlook app.</li>
                    <li>At the bottom, tap on Mail.</li>
                    <li>At the top left, tap on your avatar/initials and then Junk.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the top right, tap More <img src="@/assets/faq/more-h.png" /> and then Not Junk.</li>
                </ol>
                <h3>Prevent emails from going to Spam</h3>
                <p>
                    Marking the email as not spam should prevent future emails from going to the spam folder. You can also add <a href="Javascript:void(0)" disabled>no-reply@gravitii.care</a> to your Contacts to make sure Gravitii emails do not go to spam in the future.
                </p>
            </div>
            <div v-if="mailApp=='icloud'" id="icloud">
                <h2>iClould</h2>
                <h3>Remove an email from Spam</h3>
                <h3>Computer</h3>
                <ol>
                    <li>On your computer, open <a target="_blank" href="https://www.icloud.com/mail/">iClould Mail.</a></li>
                    <li>In the main menu, on the left, click Junk.</li>
                    <li>Click on the email that you want to remove.</li>
                    <li>At the top, click More <img src="@/assets/faq/icloud-more.png" />  and then Move to Inbox.</li>
                </ol>
                <h3>iPhone/iPad</h3>
                <ol>
                    <li>On your iPhone or iPad, open the Mail app.</li>
                    <li>In Mailboxes, tap Junk.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the bottom, tap Move <img src="@/assets/faq/icloud-folder.png" /> and then Inbox.</li>
                </ol>

                <h3>Prevent emails from going to Spam</h3>
                <p>
                    Marking the email as not spam should prevent future emails from going to the spam folder. You can also add <a href="Javascript:void(0)" disabled>no-reply@gravitii.care</a>
                    to your Contacts to make sure Gravitii emails do not go to spam in the future.
                </p>
            </div>
            <div v-if="mailApp=='yahoo'" id="yahoo">
                <h2>Yahoo</h2>
                <h3>Remove an email from Spam</h3>
                <h3>Computer</h3>
                <ol>
                    <li>On your computer, open <a target="_blank" href="https://mail.yahoo.com/">Yahoo Mail</a>.</li>
                    <li>In the main menu, on the left, click on More and then Spam.</li>
                    <li>Check the box next to the email that you want to remove.</li>
                    <li>At the top, click Not Spam.</li>

                </ol>
                <h3>Android</h3>
                <ol>
                    <li>On your Android device, open the Yahoo Mail app.</li>
                    <li>At the bottom left, tap Inbox and then Spam.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the bottom right, tap More <img src="@/assets/faq/more.png" /> and then Not spam.</li>
                </ol>
                <h3>iPhone/iPad</h3>
                <ol>
                    <li>On your iPhone or iPad, open the Yahoo Mail app.</li>
                    <li>At the bottom left, tap Inbox and then Spam.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the bottom right, tap More <img src="@/assets/faq/more-h.png" /> and then Not spam.</li>
                </ol>
                <h3>Prevent emails from going to Spam</h3>
                <p>
                    Marking the email as not spam should prevent future emails from going to the spam folder. You can also add <a href="Javascript:void(0)" disabled>no-reply@gravitii.care</a> to your Contacts to make sure Gravitii emails do not go to spam in the future.

                </p>
            </div>
            <div v-if="mailApp=='proton'" id="proton">
                <h2>Proton</h2>
                <h3>Remove an email from Spam</h3>

                <h3>Computer</h3>
                <ol>
                    <li>On your computer, open <a target="_blank" href="https://mail.proton.me/">Proton Mail</a>.</li>
                    <li>In the main menu, on the left, click on More and then Spam.</li>
                    <li>Check the box next to the email that you want to remove.</li>
                    <li>At the top, click Move to inbox (not Spam) <img src="@/assets/faq/proton-notspam.png" />.</li>
                </ol>
                <h3>Android</h3>
                <ol>
                    <li>On your Android device, open the Proton Mail app.</li>
                    <li>At the top left, tap Menu <img src="@/assets/faq/menu.png" />  and then Spam.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the top right, tap Move <img src="@/assets/faq/move.png" />  and then Inbox.</li>
                </ol>
                <h3>iPhone/iPad</h3>
                <ol>
                    <li>On your iPhone or iPad, open the Yahoo Mail app.</li>
                    <li>At the top left, tap Menu <img src="@/assets/faq/menu.png" /> and then Spam.</li>
                    <li>Open the email you want to remove.</li>
                    <li>At the top right, tap More <img src="@/assets/faq/more-h.png" />  and then Not spam (move to inbox).</li>
                </ol>
                <h3>Prevent emails from going to Spam</h3>
                <p>
                    Marking the email as not spam should prevent future emails from going to the spam folder. You can also add <a href="Javascript:void(0)" disabled>no-reply@gravitii.care</a> 
                    to your Contacts or add it to your <a target="_blank" href="https://proton.me/support/spam-filtering#how-to-add-senders-to-your-allow-list">allowed senders</a> list to make sure Gravitii emails do not go to spam in the future.

                </p>
                </div>
            </div>
    </FAQQuestion>

</template>
<script>
    import FAQQuestion from './FAQQuestion.vue';

    export default {
        components: {
            FAQQuestion
        },
        data() {
            return {
                mailApp: "gmail"
            };
        },
        mounted() {
        },
        methods: {
        }
    };
</script>
<style>
    .mail-app-tabs {
        margin-bottom: 1em;
        margin-top: 2em;
        display: flex;
        gap: 1em;
    }

        .mail-app-tabs div {
            font-size: 1.2em;
            background-color: #fafafa;
            border: solid 1px #fafafa;
            padding: 10px 20px;
            border-radius: 7px;
        }

        .mail-app-tabs div:hover,
        .mail-app-tabs div.selected {
            background-color: #fafafa;
            border: solid 1px #ddd;
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
        }
</style>