<template>
    <div>
        <gButton @click="register" class="register-button">
            Sign Up
        </gButton>
        <gButton v-if="!who" @click="login" class="login-button">
            Login
        </gButton>
        <FAQButton v-if="who" :who="who"></FAQButton>
    </div>
</template>
<script>
    import AppButton from './AppButton.vue'
    import FAQButton from './faq/FAQButton.vue'

    export default {
        components: {
            gButton: AppButton,
            FAQButton
        },
        data() {
            return {

            };
        },
        props: {
            who: String
        },
        computed: {
            whoQueryString() {
                let w = "";
                if (this.who == "patient")
                    w = "&who=patient";
                if (this.who == "provider")
                    w = "&who=provider";
                return w;
            }
        },
        methods: {
            register: function () {
                window.location.href = `https://portal.gravitii.care/?action=register${this.whoQueryString}`;
            },
            login: function () {
                window.location.href = `https://portal.gravitii.care/?action=login${this.whoQueryString}`;
            }
        }
    };
</script>
<style scoped>
    .register-button, .login-button {
        margin-right: 1em;
    }

    .check-availability {
        margin-top: 1em;
        color: #133053;
    }

        .check-availability a {
            color: #133053;
            text-decoration: none;
        }
            .check-availability a:hover {
                text-decoration: underline;
            }

            /*    @media (max-width: 1193px) {
        .icon.button {
            width: 150px;
            margin: 0.5em 0.5em 0 0;
            font-size: 0.8em;
        }
    }
*/
            @media (max-width: 1459px) {
                .register-button, .login-button {
            margin: 0.5em 0.5em 0 0;
            width: 95px;
            font-size: 0.8em;
        }
    }
</style>