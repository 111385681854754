<template>
    <div class="view vancouverpickleball-landing-page">
        <section>
            <div class="flex-container">
                <PageHero id="home" :heroImage="require('@/assets/pickleball-banner.png')">
                    <h1>
                        <span class="orange">Quality home care,</span><br />
                        <b>on your terms</b>
                    </h1>
                    <p>
                        Take your home care experience into your own hands. Signing up with Gravitii is <b>free</b>, and you only pay after you receive care.
                    </p>
                </PageHero>
                <SectionBadges id="badges" />
                <SectionWhyGravitii id="whygravitii">
                </SectionWhyGravitii>
                <SectionGetStarted id="getstarted" />
                <SectionTestimonials id="testimonials" />
                <FAQ />
            </div>
        </section>
    </div>
</template>

<script>
    import PageHero from '../../components/PageHeroCustom.vue';
    import SectionBadges from '../../components/SectionBadges.vue';
    import SectionWhyGravitii from '../../components/SectionWhyGravitii.vue';
    import SectionGetStarted from '../../components/SectionGetStarted.vue';
    import SectionTestimonials from '../../components/SectionTestimonials.vue';
    import FAQ from '../../components/faq/FAQ.vue';

    let meta = {
        type: "website",
        title: "Quality home care, on your terms.",
        description: "Take your home care experience into your own hands. Signing up with Gravitii is free, and you only pay after you receive care.",
        image: window.location.origin + "/vancouverpickleball/feature.jpg"
    };

    export default {
        name: "vancouverpickleballLandingPageView",
        components: {
            PageHero,
            SectionBadges,
            SectionWhyGravitii,
            SectionGetStarted,
            SectionTestimonials,
            FAQ
        },
        data() {
            return {

            };
        },
        metaInfo() {
            return {
                title: meta.title,
                meta: [
                    { name: 'description', content: meta.description },

                    { property: 'og:url', content: window.location.origin },
                    { property: 'og:type', content: meta.type },
                    { property: 'og:title', content: meta.title },
                    { property: 'og:description', content: meta.description },
                    { property: 'og:image', content: meta.image },

                    { property: 'twitter:url', content: window.location.origin },
                    { property: 'twitter:type', content: meta.type },
                    { property: 'twitter:title', content: meta.title },
                    { property: 'twitter:description', content: meta.description },
                    { property: 'twitter:image', content: meta.image },
                ]
            }
        }
    };
</script>
<style>
    .vancouverpickleball-landing-page .hero {
        background-position: bottom;
        min-height: 800px;
    }


    .vancouverpickleball-landing-page .menu {
        display: none;
    }


    .vancouverpickleball-landing-page h1 {
        font-size: 60px;
        font-weight: bold;
    }

        .vancouverpickleball-landing-page h1 .orange {
            color: #e67737;
        }

    @media (max-width: 1440px) {
        .vancouverpickleball-landing-page .hero .content h1.vancouverpickleball {
            font-size: 50px;
        }

        .vancouverpickleball-landing-page .hero .content p {
            font-size: 1em;
        }
    }

    @media (max-width: 700px) {
        .vancouverpickleball-landing-page .hero .content h1.vancouverpickleball {
            font-size: 40px;
        }

        .vancouverpickleball-landing-page .hero .content {
            margin-top: 0;
            height: auto;
        }

            .vancouverpickleball-landing-page .hero .content .text {
                background: none;
            }

        .vancouverpickleball-landing-page .hero {
            background-size: contain;
            min-height: 500px;
        }
    }
</style>