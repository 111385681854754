<template>
    <section class=" highlight-color option2">
        <div class="why-gravitii">
            <div class="title">
                <h3>Why Gravitii? <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h3>
                <slot name="header">
                    <h1>What makes us <b>different?</b></h1>
                    <div class="row">
                        Unlike traditional home care companies that tie you into contracts and service minimums, Gravitii gives you <b>full control of your care without compromising safety and security</b>.
                    </div>
                </slot>
            </div>
            <div>
                <div class="flex-row-wrapped">
                    <div class="benefit"><img class="icon" src="@/assets/icons/green-circle-check.png" /> <div><b>Choose</b> your provider</div></div>
                    <div class="benefit"><img class="icon" src="@/assets/icons/green-circle-check.svg" /> <span>Flexible to <b>any budget</b></span></div>
                    <div class="benefit"><img class="icon" src="@/assets/icons/green-circle-check.svg" /> <span>Enjoy <b>top security features</b></span></div>
                    <div class="benefit"><img class="icon" src="@/assets/icons/green-circle-check.svg" /> <span><b>Registered</b> health care aides</span></div>
                    <div class="benefit"><img class="icon" src="@/assets/icons/green-circle-check.svg" /> <span><b>FREE</b> 30-minute provider consult</span></div>
                </div>


                <div class="flex-row">
                    <div class="highlight col"><img class="icon" src="@/assets/icons/mapleleaf.svg" /> <b>We are 100% Canadian</b></div>
                    <div class="col">
                        <p>This means <b>your data is stored securely in Canada</b>.</p>
                        <p>If you call us, one of our team members in BC will pick up (not a robot or a person overseas).</p>
                        <p>We are your neighbours*, not your neighbors.</p>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="highlight col"><img class="icon" src="@/assets/icons/dont.svg" /> <b>We don't believe in contracts</b></div>
                    <div class="col">
                        <p>Your care needs are unique and may not always be consistent, so why would you settle for services that tie you down?</p>
                        <p>Gravitii gives you the freedom to <b>book what you need, when you need it</b>.</p>
                    </div>
                </div>
                <div class="flex-row">
                    <div class="highlight col"><img class="icon" src="@/assets/icons/green-shield-check.svg" /> <b>We take safety very seriously</b></div>
                    <div class="col">
                        <p>All care providers are registered with the <b>BC Care Aide & Community Health Worker Registry</b>.</p>
                        <p>You can choose care providers who have <b>background checks, driver's abstracts, liability insurance, and WCB coverage</b>.</p>
                        <p>You can also view ratings and reviews from other people who have received in-home care services from providers on the platform.</p>
                    </div>
                </div>

                <AppButton class="register" @click.prevent="register">{{ButtonText}}</AppButton>
            </div>

        </div>
    </section>
</template>
<script>
    import AppButton from "./AppButton.vue";

    export default {
        components: {
            AppButton
        },
        props: {
            ButtonText: {
                type: String,
                default: "Sign up for free"
            }
        },
        data: function () {
            return {
            };
        },
        methods: {
            register: function () {
                window.open('https://portal.gravitii.care/?action=register&who=patient', '_blank');
            },
        }
    };
</script>
<style>
    .why-gravitii {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 2em;
        width: 1150px;
        max-width: 100%;
        box-sizing: border-box;
    }

    .why-gravitii img {
        max-width: 100%;
    }

        .why-gravitii .title {
            margin-bottom: 2em;
        }

        .why-gravitii .flex-row-wrapped {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            gap: 1em;
            margin-bottom: 4em;
        }

            .why-gravitii .flex-row-wrapped .benefit {
                margin: 0;
                padding: 0;
                line-height: 32px;
                white-space: nowrap;
                width: 300px;
            }

                .why-gravitii .flex-row-wrapped .benefit .icon {
                    width: 32px;
                    height: 32px;
                    margin: 0 8px 0 0;
                    float: left;
                }

                .why-gravitii .flex-row-wrapped .benefit span,
                .why-gravitii .flex-row-wrapped .benefit div {
                    position: relative;
                    margin: 0;
                    padding: 0px;
                    line-height: 32px;
                    text-align: left;   
                }


                .why-gravitii .flex-row {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 2em;
                }


                    .why-gravitii .flex-row .highlight {
                        line-height: 40px;
                        font-size: 32px;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                    }

                        .why-gravitii .flex-row .highlight b {
                            display: block;
                            line-height: 40px;
                            white-space: nowrap;
                        }

                        .why-gravitii .flex-row .highlight img {
                            height: 40px;
                            float: left;
                            margin-right: 8px;
                        }


    @media (max-width: 530px) {
        .why-gravitii .flex-row .highlight {
            font-size: 24px;
        }

        .why-gravitii .flex-row .highlight b {
            white-space: normal;
        }
    }

</style>