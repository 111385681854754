<template>
    <div class="faq-more">
        <h1>Don't see your question answered?</h1>
        <p>Send us your questions and we will be more than happy to help. Reach out to our <a href="mailto:support@gravitii.care">support</a> team.  Give us a call at 1-778-946-0075.</p>

    </div>
</template>
<script>
    export default {
        components: {
        },
        data() {
            return {

            };
        },

        methods: {
        }
    };
</script>
<style scoped>
    .faq-more {
        border: solid 1px #ddd;
        border-radius: 15px;
        background-color: #fafafa;
        margin: 3em;
        padding: 2em;
    }

    @media (max-width: 600px) {
        .faq-more {
            margin: 0;
        }
    }

</style>