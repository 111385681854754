<template>
    <span class="view">
        <PageHero id="home" />
        <SectionGetCare id="getcare" />
        <SectionTestimonials id="testimonials" />
        <SectionGiveCare id="givecare" />
        <SectionVision id="vision" />
        <SectionAboutUs id="aboutus" />
        <EventAd></EventAd>
    </span>
</template>

<script>
    import PageHero from '../components/PageHero.vue';
    import SectionVision from '../components/SectionVision.vue';
    import SectionGetCare from '../components/SectionGetCare.vue';
    import SectionGiveCare from '../components/SectionGiveCare.vue';
    import SectionTestimonials from '../components/SectionTestimonials.vue';
    import SectionAboutUs from '../components/SectionAboutUs.vue';
    import EventAd from '../components/events/EventAd.vue';

    let meta = {
        type: "website",
        title: "Gravitii - Redefining home care... one connection at a time",
        description: "Gravitii modernizes the process of accessing care by leveraging technology to help deliver a more personalized, effective and timely home care experience for you or your loved ones.",
        image: window.location.origin + "/feature.jpg"
    };

    export default {
        name: "App",
        components: {
            PageHero,
            SectionVision,
            SectionGetCare,
            SectionGiveCare,
            SectionTestimonials,
            SectionAboutUs,
            EventAd
        },
        metaInfo() {
            return {
                title: meta.title,
                meta: [
                    { name: 'description', content: meta.description },

                    { property: 'og:url', content: window.location.origin },
                    { property: 'og:type', content: meta.type },
                    { property: 'og:title', content: meta.title },
                    { property: 'og:description', content: meta.description },
                    { property: 'og:image', content: meta.image },

                    { property: 'twitter:url', content: window.location.origin },
                    { property: 'twitter:type', content: meta.type },
                    { property: 'twitter:title', content: meta.title },
                    { property: 'twitter:description', content: meta.description },
                    { property: 'twitter:image', content: meta.image },
                ]
            }
        }
    };
</script>
<style>
</style>