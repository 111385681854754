let config;

if (process.env.NODE_ENV === "production") {
    config = {
        $api_url: "https://api.dev.gravitii.care/api",
        $blogger: {
            base_url: "https://www.googleapis.com/blogger/v3/blogs/",
            key: "AIzaSyAI4yvHYIeYcrcF_Qt8bYvOiLzu7ODACnQ",
            blogId: "7776661160687286767"
        }
    };
} else {
    config = {
        $api_url: "https://localhost:7268/api",
        $blogger: {
            base_url: "https://www.googleapis.com/blogger/v3/blogs/",
            key: "AIzaSyAI4yvHYIeYcrcF_Qt8bYvOiLzu7ODACnQ",
            blogId: "7776661160687286767"
        }
    };
}

export { config }
