<template>
    <section class="highlight-color option2">
        <div class="our-testimonials">
            <div class="intro">
                <h3>Testimonials <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h3>
                <h1><b>What</b> people say</h1>
                <img class="testimonial-likes" src="@/assets/testimonial-likes.png" />
            </div>
            <div class="flex-container">
                <div class="testimonials">
                    <div :class="applyClass(testimonial)" v-for='testimonial in takeRandom(4)' v-bind:key='testimonial.id'>
                        <div class="what">{{ testimonial.text }}</div>
                        <div class="who">{{ testimonial.name }}<span>, {{ testimonial.title }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import testimonials from '@/data/testimonials.json';

    export default {
        components: {
        },
        data: function () {
            return {
                testimonials: testimonials
            };
        },
        methods: {
            takeRandom: function (n) {
                return this.shuffle().slice(0, n);
            },
            shuffle: function () {
                let array = this.testimonials;
                let currentIndex = array.length, randomIndex;

                // While there remain elements to shuffle.
                while (currentIndex != 0) {

                    // Pick a remaining element.
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex--;

                    // And swap it with the current element.
                    [array[currentIndex], array[randomIndex]] = [
                        array[randomIndex], array[currentIndex]];
                }

                return array;
            },
            applyClass: function (testimonial) {
                return `testimonial ${testimonial.category}`;
            }
        }
    };
</script>
<style>
    .our-testimonials {
        padding: 4em;
    }

        .our-testimonials .testimonial-likes {
            position: relative;
            float: right;
            right: 0;
            margin-top: -310px;
            margin-right: 80px;
        }

        .testimonials {
            display: flex;
            flex-wrap: wrap;
            gap: 2em;
            width: 100%;
            margin-top: 2em;
            align-content: stretch;
            justify-content: space-evenly;
        }

    .testimonial {
        border: solid 1px #ddd;
        background-color: #fff;
        border-radius: 11px;
        padding: 3em;
        font-size: 0.5em;
        margin-bottom: 1.5em;
        box-sizing: border-box;
        width: 48%;
        height: auto;
    }

        .testimonial.givecare {
            /*background-color: rgba(19, 48, 83, 0.1);*/
        }

        .testimonial.getcare {
            /*background-color: rgba(230, 119, 55, 0.1);*/
        }

        .testimonial .who {
            margin-right: 3em;
            font-weight: 700;
            text-align: right;
        }

            .testimonial .who span {
                font-weight: normal;
                font-size: 0.7em;
            }

    @media (max-width: 1045px) {
        .testimonial {
            width: 100%;
            height: auto;
        }

        .our-testimonials .testimonial-likes {
            width: 220px;
            margin-top: -270px;
            margin-right: 30px;
        }
    }

    @media (max-width: 600px) {
        .our-testimonials {
            padding: 2em;
        }
    }
</style>