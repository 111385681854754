<template>
    <section class=" patient">
        
        <div class="get-care">

            <h2 class="free"><strong>Free</strong> to sign up!</h2>

            <div class="title">
                <h3>Get Care <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h3>
                <h1>Find a <b>personal connection</b> with a <b>care provider</b> near you</h1>
            </div>
            <PlatformActions class="platform-actions" who="patient"></PlatformActions>
            <div class="flex-container">
                <div class="col">
                    <div class="flex-container">

                        <gInfoTile icon="match">
                            <h1>Find your best match</h1>
                            <p>
                                Our solution not only simplifies the process of finding the most suitable care
                                provider, but also ensures that care providers get to work with patients who
                                they can best assist.
                            </p>
                        </gInfoTile>
                        <gInfoTile icon="human">
                            <h1>Trusted professional care</h1>
                            <p>
                                Gravitii is your solution for
                                connecting with registered
                                homecare professionals.
                                Build a long-term
                                relationship with a trusted
                                care provider.
                            </p>
                        </gInfoTile>
                        <gInfoTile icon="personal">
                            <h1>Easy to use</h1>
                            <p>
                                Gravitii makes it easy to
                                communicate, schedule
                                appointments, and manage
                                your care with a homecare
                                professional, all f rom a
                                personalized dashboard.
                            </p>
                        </gInfoTile>
                    </div>
                </div>

                <div class="col">

                    <p>
                        At Gravitii, we understand first-hand the growing demand for home care, and the
                        increasing challenges that the current healthcare system is facing trying to meet these
                        needs. Restrictive policies, inefficient administration, and high costs are some of the many
                        reasons the system is failing to support individuals to live independently.

                    </p>
                    <p>
                        For those getting care, these reasons can amount to unaffordable and inconsistent
                        care, with different providers regularly showing up who are unfamiliar with your care needs.
                        Often, this results in less than optimal care.
                    </p>
                    <p>
                        Home care plays an integral role in supporting
                        individuals to continue living in the comfort of their
                        home. This includes access to services such as:
                        <ul>
                            <li>Management of chronic conditions</li>
                            <li>Wellness and safety</li>
                            <li>Companionship</li>
                            <li>Care coordination</li>
                            <li>Help in and around the home</li>
                            <li>Activities of daily living</li>
                            <li>Transitional care</li>
                        </ul>
                    </p>
                </div>

                <div class="sign-up">
                    <h3>I'm looking for home care for myself or a loved one, <a href="https://portal.gravitii.care/?who=patient&action=register">sign me up</a>!</h3>
                </div>
            </div>
        </div>
        <gNewsletter list="patient" :visible="showWindow" @close="toggleWindow"></gNewsletter>
    </section>
</template>
<script>
    import AppInfoTile from './AppInfoTile.vue';
    import WindowNewsletter from './WindowNewsletter.vue';
    import PlatformActions from './PlatformActions.vue';

    let showWindow = false;

    export default {
        components: {
            gInfoTile: AppInfoTile,
            gNewsletter: WindowNewsletter,
            PlatformActions: PlatformActions
        },
        data: function () {
            return {
                showWindow
            };
        },
        methods: {
            toggleWindow: function () {
                this.showWindow = !this.showWindow;
            }
        }
    };
</script>
<style>
    .get-care {
        padding: 4em;
        margin-top: 3em;
    }

        .get-care img {
            max-width: 100%;
        }

        .get-care .flex-container {
            display: flex;
            flex-wrap: wrap;
            gap: 2em;
            margin-top: 2em;
            align-content: center;
            align-items: flex-start;
        }

        .get-care .free {
            font-style: normal;
            font-weight: 200;
            font-size: 2.5em;
            line-height: 1.02em;
            letter-spacing: -0.03em;
            color: #e67737;
            position: absolute;
            left: 20;
            -webkit-transform: rotate(40deg);
            transform: rotate(0deg);
            padding-right: 1em;
            margin-top: -2em;
        }

            .get-care .free strong {
                font-weight: 900;
            }

        .get-care .sign-up {
            width: 100%;
            text-align: center;
        }

        .get-care .flex-container .col {
            flex: 40%;
        }

        .get-care .info-tile {
            align-self: center;
            width: 100%;
        }

    @media (max-width: 769px) {
        .get-care img {
            float: none;
        }

        .get-care .flex-container .col {
            flex: 100%;
            width: 100%;
        }

        .get-care > .flex-container > .col > .flex-container {
            margin-top: 2em;
        }
    }

    @media (max-width: 600px) {
        .get-care {
            padding: 2em;
        }
    }

    @media (max-width: 450px) {
        .get-care {
            margin-top: 2em;
        }

            .get-care .free {
                font-size: 2em;
            }
    }
</style>