<template>
    <section class="hero-section">
        <div :class="'hero ' + heroClass" @mouseenter="heroPause=true" @mouseleave="heroPause=false">
            <PageHeader />
            <div class="content">
                <div class="text">
                    <div v-if="heroIndex==0">
                        <h1>Why choose Gravitii for home care?</h1>
                        <p>
                            Gravitii.care is a platform that connects you with compassionate, professional home care providers. We offer a personalized
                            home care solution, ensuring you or your loved ones receive the care they need. Our platform is easy to use and allows you to
                            self advocate for your unique needs and preferences.
                        </p>
                    </div>
                    <div v-if="heroIndex==1">
                        <h1>Establishing Trust</h1>
                        <p>
                            Ensuring that a home care provider is trustworthy is essential to your safety and well-being. Care Providers
                            are required to upload a confirmation letter that they are in good standing with the BC Care Aide and Community Health Worker Registry.
                            <a href="/#/faq#trust">Learn more</a> about how to verify the trustworthiness of a home care provider.
                        </p>
                    </div>
                    <div v-if="heroIndex==2">
                        <h1>How does it work?</h1>
                        <p>
                            It's easy! <a href="https://portal.gravitii.care/?action=register&who=patient">Sign up</a> on our website, and we'll match you with the right care providers
                            based on your unique needs, location, and preferences. Our system will walk you through an easy registration wizard to get you started.
                            It's free to sign up and view who is available to provide care in your area.
                        </p>
                    </div>
                    <div v-if="heroIndex==3">
                        <h1>Is care available in my area?</h1>
                        <p>
                            Gravitii is currently operating in British Columbia.  We started close to home in the Central Okanagan and the Greater Victoria regions and new care providers are signing on everyday across British Columbia.
                            Please <a href="https://portal.gravitii.care/?action=register&who=patient">create an account</a> or <a href="/#/#contactus">contact</a> our team to confirm whether we serve your area. 
                        </p>
                    </div>
                </div>
                <gButton @click.prevent="navigateTo('#getcare')">Get Care</gButton>
                <!--<gButton @click.prevent="navigateTo('#givecare')">Give Care</gButton>-->
            </div>
            <div class="hero-bottom">
                <div class="hero-nav">
                    <div v-for="idx in 4" :key="idx" @click.prevent="show(idx-1)" :class="heroIndex==(idx-1) ? 'dot-button k-icon k-icon-xxl k-i-circle selected' : 'dot-button k-icon k-icon-xxl k-i-circle'"></div>
                </div>
                <h2 class="redefine">
                    <b>Redefining</b> home <b>care</b>...<br />
                    &emsp;&emsp;&emsp; one <b>connection</b> at a time.
                </h2>
            </div>
        </div>
    </section>
</template>
<script>
    import PageHeader from './PageHeader.vue'
    import AppButton from './AppButton.vue'

    const heroDelay = 10000;
    const heroCount = 3;
    let initialHeroIndex = 0;
    let initialHeroClass = "hero" + initialHeroIndex.toString();
    // eslint-disable-next-line
    let heroInterval = null;

    // Pre-load hero images
    for (var i = 0; i <= heroCount; i++) {
        let img = new Image();
        img.src = require("@/assets/hero/hero-" + i + ".png");
    }

    export default {
        components: {
            PageHeader,
            gButton: AppButton
        },
        data() {
            return {
                heroIndex: 0,
                heroClass: initialHeroClass,
                heroPause: false
            };
        },
        mounted() {
            const me = this;
            heroInterval = window.setInterval(function () {
                if (me.heroPause)
                    return;

                me.heroIndex = me.heroIndex + 1;
                if (me.heroIndex > heroCount) {
                    me.heroIndex = 0;
                }
                me.heroClass = "hero" + me.heroIndex.toString();
            }, heroDelay);

        },
        destroyed() {
            window.clearInterval(this.heroInterval);
        },
        methods: {
            navigateTo(loc) {
                this.$router.push(loc);
            },
            show(i) {
                this.heroIndex = i;
                this.heroClass = "hero" + i.toString();

                console.log("show", this.heroClass);
            }
        }
    };
</script>
<style scoped>
    .hero-section {
        padding: 0;
        margin: 0;
    }

    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .hero.hero0 {
        background-image: url("~@/assets/hero/hero-0.png");
    }

    .hero.hero1 {
        background-image: url("~@/assets/hero/hero-1.png");
    }

    .hero.hero2 {
        background-image: url("~@/assets/hero/hero-2.png");
    }

    .hero.hero3 {
        background-image: url("~@/assets/hero/hero-3.png");
    }

    .hero {
        padding: 0;
        padding-bottom: 2em;
        margin: 0;
        background-image: linear-gradient(181.63deg, rgba(245, 245, 245, 0.6) 17.05%, rgba(245, 245, 245, 0.2) 38.61%, transparent), url("~@/assets/hero/hero-2.png");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        width: 100%;
        min-height: 707px;
        /*aspect-ratio: 2.163;*/
/*        -webkit-transition: background-image 2s ease-in-out;
        transition: background-image 2s ease-in-out;*/
    }

    .hero-bottom {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 100%;
        max-width: 1919px;
        text-align: center;
    }

        .hero-bottom .hero-nav {
            display: flex;
            flex-direction: row;
            gap: 1em;
        }

        .hero-bottom .redefine {
            color: #555;
            font-weight: normal;
        }

    .dot-button {
        color: #133053;
    }

        .dot-button.selected {
            color: #e67737;
        }

    .hero .content {
        flex: 1;
        align-content: center;
        padding: 5em;
        height: 350px;
        overflow: hidden;
    }

        .hero .content p {
            margin-bottom: 2em;
            width: 569px;
            max-width: 100%;
            font-size: 1em;
            line-height: 1.7em;
            letter-spacing: -0.04em;
        }

        .hero .content button {
            margin-right: 1em;
        }

    @media (max-width: 1440px) {
        .hero .content {
            padding: 3em;
            padding-top: 1em;
        }

            .hero .content h1 {
                font-size: 1.5em;
            }

            .hero .content p {
                width: 450px;
                font-size: 0.9em;
                margin-bottom: 1em;
            }
    }

    @media (max-width: 769px) {
        .hero .content {
            flex: 100%;
            align-content: center;
            margin: 1em;
            padding: 0;
            height: 400px;
        }

            .hero .content .text {
                background: rgba(255, 255, 255, 0.7);
                border-radius: 15px;
                padding: 1em;
                margin: 0;
                margin-bottom: 1em;
                font-size: 0.9em;
                width: auto;
            }

        .hero.hero0 {
            background-position-x: -515px;
        }

        .hero.hero1 {
            background-position-x: -608px;
        }

        .hero.hero2 {
            background-position-x: -534px;
        }

        .hero.hero3 {
            background-position-x: -771px;
        }
    }

    @media (max-width: 600px) {

                .hero .content button {
                    max-width: 45%;
                }
    }

    @media (max-width: 450px) {
        .hero {
            background-position: bottom;
        }

            .hero.hero0 {
                background-position-x: -813px;
            }

            .hero.hero1 {
                background-position-x: -969px;
            }

            .hero.hero2 {
                background-position-x: -862px;
            }

            .hero.hero3 {
                background-position-x: -1070px;
            }

            .hero .content button {
                font-size: 0.8em;
                max-width: 40%;
            }
    }
</style>