<template>
    <section class="hero-section">
        <div class="hero">
            <PageHeader />
            <div class="content">
                <div class="text">
                    <slot>
                        <h1>&lt;h1&gt;Header goes here&lt;/h1&gt;</h1>
                        <p>
                            &lt;p&gt;Text content goes here&lt;/p&gt;
                        </p>
                    </slot>
                </div>
                <gButton @click.prevent="register">{{signUpText}}</gButton>
            </div>
        </div>
    </section>
</template>
<script>
    import PageHeader from './PageHeader.vue'
    import AppButton from './AppButton.vue'

    export default {
        components: {
            PageHeader,
            gButton: AppButton
        },
        props: {
            heroImage: {
                type: String,
                default: "@/assets/hero/hero-0.png"
            },
            signUpText: {
                type: String,
                default: "Find Care"
            },
            visible: Boolean
        },
        mounted() {
            console.log(this.heroImage);
        },
        data() {
            return {
            };
        },
        computed: {
            heroImageUrl() {
                return `url("${this.heroImage}")`;
            }
        },
        methods: {
            navigateTo(loc) {
                this.$router.push(loc);
            },
            register: function () {
                window.open('https://portal.gravitii.care/?action=register&who=patient', '_blank');
            },

        }
    };
</script>
<style scoped>
    .hero-section {
        padding: 0;
        margin: 0;
    }

        .hero-section .hero {
            background-image: v-bind(heroImageUrl);
        }

    .hero {
        padding: 0;
        padding-bottom: 2em;
        margin: 0;
        background-image: linear-gradient(181.63deg, rgba(245, 245, 245, 0.6) 17.05%, rgba(245, 245, 245, 0.2) 38.61%, transparent), url("~@/assets/hero/hero-2.png");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        width: 100%;
        min-height: 707px;
        /*aspect-ratio: 2.163;*/
        /*        -webkit-transition: background-image 2s ease-in-out;
        transition: background-image 2s ease-in-out;*/
    }

        .hero .content {
            flex: 1;
            align-content: center;
            padding: 5em;
            height: 350px;
            overflow: hidden;
        }

            .hero .content p {
                margin-bottom: 2em;
                width: 569px;
                max-width: 100%;
                font-size: 1em;
                line-height: 1.7em;
                letter-spacing: -0.04em;
            }

            .hero .content button {
                margin-right: 1em;
            }

    @media (max-width: 1440px) {
        .hero .content {
            padding: 3em;
            padding-top: 1em;
        }

            .hero .content h1 {
                font-size: 1.5em;
            }

            .hero .content p {
                width: 450px;
                font-size: 0.9em;
                margin-bottom: 1em;
            }
    }

    @media (max-width: 769px) {
        .hero .content {
            flex: 100%;
            align-content: center;
            margin: 1em;
            padding: 0;
            height: 400px;
        }

            .hero .content .text {
                background: rgba(255, 255, 255, 0.7);
                border-radius: 15px;
                padding: 1em;
                margin: 0;
                margin-bottom: 1em;
                font-size: 0.9em;
                width: auto;
            }

        .hero.hero0 {
            background-position-x: -515px;
        }

        .hero.hero1 {
            background-position-x: -608px;
        }

        .hero.hero2 {
            background-position-x: -534px;
        }

        .hero.hero3 {
            background-position-x: -771px;
        }
    }

    @media (max-width: 600px) {

        .hero .content button {
            max-width: 45%;
        }
    }

    @media (max-width: 450px) {
        .hero {
            background-position: bottom;
        }

            .hero.hero0 {
                background-position-x: -813px;
            }

            .hero.hero1 {
                background-position-x: -969px;
            }

            .hero.hero2 {
                background-position-x: -862px;
            }

            .hero.hero3 {
                background-position-x: -1070px;
            }

            .hero .content button {
                font-size: 0.8em;
                max-width: 40%;
            }
    }
</style>