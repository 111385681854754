<template>
    <section class=" get-started">

        <div class="padded">
            <div class="title">
                <h3>Get Started <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h3>
                <h1><b>How</b> does it work?</h1>
            </div>
            <div class="get-started-grid">
                <GetStartedItem Step="1" Title="Register" :ImageUrl="require('@/assets/screenshots/screen-register.png')">
                    <p>Registering is easy! Simply <a @click.prevent="register" href="Javascript:void(0);">create an account</a> so you can define your preferences and start browsing care providers in your area.</p>
                    <p>Whether you''re signing up for yourself or on behalf of someone else, the Gravitii platform adapts to your specific user type and needs.</p>
                </GetStartedItem>
                <GetStartedItem Step="2" Title="Find Care" :ImageUrl="require('@/assets/screenshots/screen-findcare.png')">
                    <p>Once you've registered, you can start browsing for care in your area using your postal code.</p>
                    <p>Just follow the prompts to find care based on your needs, preferences, and security features. We show you all the verified home care providers in your area so you can choose your ideal fit!</p>
                </GetStartedItem>
                <GetStartedItem Step="3" Title="Communicate" :ImageUrl="require('@/assets/screenshots/screen-communicate.png')" :IsLast="true">
                    <p>Our service wouldn''t be complete without an integrated, fully-secure communication platform!</p>
                    <p>Once you've found your ideal care provider, you can talk to them via the messaging center. Anything you send and receive via our platform remains safe and secure on our servers.</p>
                </GetStartedItem>
            </div>
            <div class="sign-up-row">
                <AppButton class="sign-up" @click.prevent="register">I want to sign up</AppButton>
            </div>
        </div>
    </section>
</template>
<script>
    import AppButton from './AppButton.vue'
    import GetStartedItem from './SectionGetStartedItem.vue'

    export default {
        components: {
            AppButton,
            GetStartedItem,
        },
        data: function () {
            return {
            };
        },
        methods: {
            register: function () {
                window.open('https://portal.gravitii.care/?action=register&who=patient', '_blank');
            },

        }
    };
</script>
<style>
    .get-started {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 2em;
        width: 1150px;
        max-width: 100%;
        box-sizing: border-box;
    }

    .get-started-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 1em;
        margin-bottom: 2em;
    }

    .get-started .sign-up-row {
        width: 100%;
        text-align: center;
    }

    .get-started .title {
        margin-bottom: 3em;
    }

    .get-started img {
        max-width: 100%;
    }

    @media (max-width: 700px) {
        .get-started .title {
            margin-bottom: 0;
        }
    }

</style>