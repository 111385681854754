<template>
    <section class=" highlight-color option2 core-features">
        <div class="padded">
            <div class="title">
                <h3>Core Features <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h3>
                <slot name="header">
                    <h1>Service that puts <b>your needs first</b></h1>
                    <div class="row">
                        With Gravitii, you have full control of your care without compromising safety and security.
                        We make connecting with your ideal care provider a breeze so you can receive the most personalized level of care for your specific needs.
                    </div>
                </slot>
            </div>
            <div class="flex-container">
                <div class="row">
                    <slot name="columns">
                        <div class="col">
                            <div class="icon"><img alt="Verified Health Providers" src="@/assets/icons/female-happy-face.svg" /></div>
                            <h4>Verified Healthcare Providers</h4>
                            <div>Gravitii is your solution for connecting with Registered Home Care Professionals whom you can build a long-term relationship with.</div>
                        </div>
                        <div class="col">
                            <div alt="No Contracts" class="icon"><img src="@/assets/icons/pointer.svg" /></div>
                            <h4>No Contracts</h4>
                            <div>Book care that suits your budget. Gravitii connects you with quality care while giving you the flexibility to book what you need, when you need it.</div>
                        </div>
                        <div class="col">
                            <div alt="Enhanced Safety & Security" class="icon"><img src="@/assets/icons/thumbs-up.svg" /></div>
                            <h4>Enhanced Safety & Security</h4>
                            <div>We help you find care providers with verified background checks, social ratings and reviews, liability insurance, and WCB coverage.</div>
                        </div>
                    </slot>
                </div>
                <AppButton class="register" @click.prevent="register">{{ButtonText}}</AppButton>
            </div>

        </div>
    </section>
</template>
<script>
    import AppButton from "./AppButton.vue";

    export default {
        components: {
            AppButton
        },
        props: {
            ButtonText: {
                type: String,
                default: "Sign up for free"
            }
        },
        data: function () {
            return {
            };
        },
        methods: {
            register: function () {
                window.open('https://portal.gravitii.care/?action=register&who=patient', '_blank');
            },
        }
    };
</script>
<style>
    .core-features {
        background: url(@/assets/careaide2.png);
        background-position: 0px 90px;
        background-size: 1100px;
        background-repeat: no-repeat;
    }

        .core-features .register {
            margin-top: 2em;
        }

        .core-features .title {
            margin-bottom: 2em;
        }

        .core-features .padded {
            padding: 4em;
            margin-top: 3em;
            margin-left: 470px;
        }

        .core-features img {
            max-width: 100%;
        }


        .core-features .flex-container {
            display: flex;
            flex-wrap: wrap;
        }

            .core-features .flex-container .row {
                display: flex;
                flex-direction: row;
                gap: 2em;
            }

            .core-features .flex-container .col {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }


    @media (max-width: 1500px) {
        .core-features {
            background-position: 0px 190px;
            background-size: 1000px;
        }

            .core-features .padded {
                margin-left: 400px;
            }
    }

    @media (max-width: 1150px) {
        .core-features {
            background-position: 0px 320px;
            background-size: 800px;
        }

            .core-features .padded {
                margin-left: 310px;
            }
    }

    @media (max-width: 950px) {
        .core-features {
            background-position: 0px 150px;
            background-size: 550px;
        }

            .core-features .title {
                margin-left: 200px;
                margin-bottom: 60px;
            }

            .core-features .padded {
                margin-left: 0px;
            }
    }

    @media (max-width: 500px) {
        .core-features {
            background-image: none;
        }

            .core-features .flex-container .row {
                flex-wrap: wrap;
            }

            .core-features .title {
                margin-left: 0;
                margin-top: 0;
                margin-bottom: 60px;
            }

            .core-features .padded {
                margin-left: 0;
                margin-top: 0;
                padding: 2em;
            }
    }
</style>