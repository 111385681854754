<template>
    <div class="faq">
        <h4 :class="expanded ? 'expanded' : ''" @click="expanded = !expanded">
            <span v-if="!expanded" class="k-icon k-font-icon k-icon-lg k-i-plus"></span>
            <span v-if="expanded" class="k-icon k-font-icon k-icon-lg k-i-minus"></span>
            <slot name="question"></slot>
        </h4>
        <Reveal :appear="expanded">
            <div class="content" v-if="expanded">
                <slot></slot>
            </div>
        </Reveal>
    </div>
</template>
<script>
    import { Reveal } from '@progress/kendo-vue-animation';

    export default {
        components: {
            'Reveal': Reveal
        },
        data() {
            return {
                expanded: false
            };
        },
        mounted() {
            const id = this.$el.id;
            const hash = window.location.hash;
            if (id && this.target && id.toLowerCase() == this.target.toLowerCase()) {
                this.expanded = true;
            }
            else if (id && hash) {
                if (hash.indexOf("#" + id) > 0) {
                    window.setTimeout(() => {
                        this.expanded = true;
                        try {
                            document.getElementById(id).scrollIntoView();
                        } catch {
                            // Nothing to do
                        }
                    }, 500);

                }
            }
        },
        props: {
            target: String
        },
        methods: {
        }
    };
</script>
<style>
    .faq h4 {
        font-size: 22px;
        margin: 0;
        cursor: pointer;
    }

    .faq .content {
        padding-left: 1.5em;
        margin-bottom: 2em;
    }
</style>