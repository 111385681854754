<template>
    <section id="contactus" class="footer">
        <div class="footer">
            <h1>Don't hesitate to <b>reach us</b> <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h1>
            <div class="flex-container">
                <div class="col">
                    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
                        <form @submit.prevent="handleSubmit(onSubmit)">
                            <h3>How can we help?</h3>
                            <div>
                                <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }">
                                    <label for="email">
                                        Email
                                    </label>
                                    <input id="email" v-model="email" type="email">
                                    <span class="err">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <div>
                                <ValidationProvider name="Message" rules="required" v-slot="{ errors }">
                                    <label for="message">
                                        Message
                                    </label>
                                    <textarea id="message" rows="3" class="k-textbox" v-model="message"></textarea>
                                    <span class="err">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <gButton id="submit" @click.prevent="handleSubmit(onSubmit)" :disabled='sending||invalid'>Submit</gButton>
                            <Transition>
                                <span v-show="showSuccess" class="success">Your message has been sent!</span>
                            </Transition>
                        </form>
                    </ValidationObserver>

                </div>
                <div class="col">
                    <div class="quicklinks">
                        <h3>Quicklinks</h3>
                        <ul>
                            <li><a @click.prevent="toggleNewsletterWindow" href="#">Newsletter</a></li>
                            <li><a @click.prevent="toggleInvestorWindow" href="#">Investors</a></li>
                            <li><router-link to="/events">Events</router-link></li>
                            <li><router-link to="/terms">Terms</router-link></li>
                            <li><router-link to="/privacy">Privacy</router-link></li>
                            <!--<li><router-link to="/cookies">Cookie Policy</router-link></li>-->
                        </ul>
                    </div>
                </div>
                <div class="col">
                    <div class="contact">
                        <h3>Contact</h3>
                        <div class="phone"><a href="tel:17789460075">1-778-946-0075</a></div>
                        <h3>Office hours</h3>
                        <table class="hours">
                            <tr v-for="item in hours" :key="item.day">
                                <td>{{ item.day }}</td>
                                <td v-if="item.closed">closed</td>
                                <td class="nowrap" v-else>{{ item.open }} am to {{ item.close }} pm</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="memberships col">
                    <a class="chamber" href="https://secure.kelownachamber.org/Senior-Services/Gravitiicare-13600" target="_blank">
                        <img src="@/assets/kelowna-chamber-proud-member.png" alt="Kelowna Chamber Proud Member" />
                    </a>
                    <a class="vbb" href="https://www.canadianlegacy.org/" target="_blank">
                        <img src="@/assets/vbb.png" style="border: 0;" alt="Certified VBB Business - Canadian Legacy Project" />
                    </a>

                    <a class="bbb" href="https://www.bbb.org/ca/bc/kelowna/profile/information-technology-services/gravitii-technologies-0037-2430757/#sealclick" target="_blank" rel="nofollow">
                        <img src="https://seal-mbc.bbb.org/seals/blue-seal-293-61-bbb-2430757.png" style="border: 0;" alt="Gravitii Technologies BBB Business Review" />
                    </a>
                </div>
                <div class="social col">
                    <a href="https://www.linkedin.com/company/gravitii-technologies-inc/" target="_blank"><img src="@/assets/social/linkedin.svg" alt="LinkedIn" /></a>
                    <a href="https://www.facebook.com/gravitii.care" target="_blank"><img src="@/assets/social/facebook.svg" alt="Facebook" /></a>
                    <a href="https://www.instagram.com/gravitii.care/" target="_blank"><img src="@/assets/social/instagram.svg" alt="Instagram" /></a>
                    <a href="https://www.youtube.com/@gravitii_care" target="_blank"><img src="@/assets/social/youtube.svg" alt="Youtube" /></a>
                    <!--
                        <a href="" target="_blank"><img src="@/assets/social/twitter.svg" /></a>
                    -->

                </div>
            </div>
            <div class="copyright">
                &copy; Copyright {{ currentYear }}, Gravitii Technologies Inc. All rights reserved.
            </div>
        </div>
        <gNewsletter list="investors" :visible="showInvestorWindow" @close="toggleInvestorWindow"></gNewsletter>
        <gNewsletter list="newsletter" :visible="showNewsletterWindow" @close="toggleNewsletterWindow"></gNewsletter>
    </section>
</template>
<script>
    import AppButton from './AppButton.vue';
    import WindowNewsletter from './WindowNewsletter.vue';

    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    let email = "";
    let message = "";
    let sending = false;
    let invalid = false;
    let showSuccess = false;
    let showNewsletterWindow = false;
    let showInvestorWindow = false;

    export default {
        components: {
            gButton: AppButton,
            ValidationObserver,
            ValidationProvider,
            gNewsletter: WindowNewsletter
        },
        data: function () {
            return {
                currentYear: new Date().getFullYear(),
                email,
                message,
                sending,
                invalid,
                showSuccess,
                showNewsletterWindow,
                showInvestorWindow,
                hours: [
                    { day: "Monday", open: "8:00", close: "5:00" },
                    { day: "Tuesday", open: "8:00", close: "5:00" },
                    { day: "Wednesday", open: "8:00", close: "5:00" },
                    { day: "Thursday", open: "8:00", close: "5:00" },
                    { day: "Friday", open: "8:00", close: "5:00" },
                    { day: "Saturday", closed: true },
                    { day: "Sunday", closed: true },
                    { day: "Holidays", closed: true },
                ]
            };
        },
        methods: {
            toggleNewsletterWindow: function () {
                this.showNewsletterWindow = !this.showNewsletterWindow;
            },
            toggleInvestorWindow: function () {
                this.showInvestorWindow = !this.showInvestorWindow;
            },
            onSubmit: function () {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return;
                    }

                    // Prevent multiple clicks.
                    if (this.sending)
                        return;

                    this.sending = true;

                    let url = this.appConfig.$api_url + "/contact/contactus/";

                    let msg = {
                        email: this.email,
                        message: this.message
                    };

                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(msg)
                    };

                    fetch(url, requestOptions)
                        .then(() => {
                            this.showSuccess = true;
                            this.email = "";
                            this.message = "";
                            this.sending = false;
                            this.$nextTick(() => {
                                this.$refs.form.reset();
                                setTimeout(() => this.showSuccess = false, 3000);
                            });
                        });
                });
            }
        }
    };
</script>
<style>
    .footer .vbb > img {
        height: 120px;
        margin-right: 1em;
    }

    .footer .chamber > img {
        height: 100px;
        border-radius: 15px;
        margin-right: 1em;
    }

    .footer .flex-container {
        display: flex;
        flex-direction: row;
        padding: 4em;
    }

    section.footer > div {
        padding: 4em;
    }

    .footer .quicklinks {
        margin-left: 4em;
    }

    .footer .contact .phone {
        margin-top: 1em;
    }

    .footer .nowrap {
        white-space: nowrap;
    }

        .footer .contact .phone a {
            color: #000;
            font-size: 1.5em;
            font-weight: bold;
            text-decoration: none;
        }

            .footer .contact .phone a:hover {
                text-decoration: underline;
            }

    .footer .contact .hours {
        font-size: 0.95em;
    }

    .footer ul {
        padding: 0;
    }

        .footer ul li {
            list-style: none;
        }

            .footer ul li a,
            .footer ul li a:visited {
                text-decoration: none;
                color: #222425;
            }

                .footer ul li a:hover {
                    text-decoration: underline;
                }

    .footer .copyright {
        width: 100%;
        text-align: center;
    }

    .footer .memberships {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer .social {
        width: 100%;
        text-align: right;
    }

        .footer .social img {
            width: 50px;
            height: 50px;
            margin-left: 0.5em;
        }

    .footer #email,
    .footer #message {
        font-size: 1em;
        line-height: 1.5em;
        width: 100%;
        border: solid 1px rgb(128, 128, 128, 0.6);
        border-radius: 5px;
    }

    .footer #submit {
        width: 100px;
        height: 1em;
        font-size: 0.8em;
        line-height: 1em;
        border-radius: 20px;
    }

    .footer form label {
        display: block;
    }

    .footer .success {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        color: #49cc90;
        background-color: #f1f9f3;
        box-shadow: 5px 5px 24px #aaa;
        padding: 1em;
        padding-left: 3em;
        z-index: 10000;
    }

    .footer .err {
        display: block;
        color: #f00;
        font-size: 0.8em;
    }

    .footer .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .footer .col {
        flex: 50%;
    }

    section.footer {
        padding-top: 150px;
        /*background: linear-gradient(90deg, #E7C668 1.35%, #BFA1EF 64.6%, rgba(194, 173, 228, 0.54) 98.51%);*/
        background-image: url("~@/assets/gold.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: cover;
    }

    .footer .v-enter-active,
    .footer .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .footer .v-enter-from,
    .footer .v-leave-to {
        opacity: 0;
    }

    @media (max-width: 1080px) {
        section.footer {
            background-position: left bottom;
            background-size: cover;
        }

            section.footer .hours td,
            section.footer .quicklinks li,
            section.footer form label {
                font-size: 0.9em;
            }
    }

    @media (max-width: 1160px) {
        .footer .row {
            flex-direction: column;
        }

/*        .footer .flex-container {
            padding: 0;
            padding-bottom: 1em;
        }*/

        .footer .col.social {
            margin-top: 1em;
            margin-bottom: 1em;
            text-align: center;
        }

        .footer .chamber > img {
            height: 70px;
        }

        .footer .vbb > img {
            height: 80px;
        }

        .footer .bbb > img {
            height: 60px;
        }

        /*        .footer .contact .hours {
            font-size: 0.8em;
        }*/
    }

    @media (max-width: 805px) {
/*        .footer {
            padding: 2em;
        }*/

            .footer .memberships {
                flex-wrap: wrap;
                align-items: center;
            }


        /*        .footer .quicklinks li {
            font-size: 0.8em;
        }*/
    }

    @media (max-width: 600px) {
        section.footer > div {
            padding: 2em;
        }

        .footer .flex-container {
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-top: 2em;
            padding: 0;
            margin-bottom: 2em;
        }

        .footer .col {
            flex: 100%;
        }

        .footer .quicklinks {
            margin-left: 0;
        }

        .footer .copyright {
            margin-top: 1em;
        }
    }
</style>