<template>
    <NotificationGroup v-if="canRegister" :style="{ bottom: 0, left: '50%', transform: 'translateX(-50%)' }">
        <Reveal :appear="events">
            <Notification v-if="events" :closable="true" @close="onClose('events')" class="event-ad">
                <div class="content">

                    <h1>Finding Home Care with Gravitii</h1>
                    <p>
                        Join us on Tuesday, January 23rd, 2024 at 3:00 pm, for a special 30-minute webinar. We'll cover important topics like setting up your (or your loved one's) care profile to find the best-matched local provider(s), the booking process, communicating with your care provider(s) to manage your/your loved one's care on-going and more!
                    </p>
                    <div class="when-where orange">
                        <div><b>Tuesday, January 23rd, 2024</b></div>
                        <div>3:00 PM - 3:30 PM</div>
                        <gButton @click.prevent="navigateTo('https://u32254365.ct.sendgrid.net/ls/click?upn=DfnzmRKjxRbpKkH3HjbyJ4g7n1vLEfvaRvhsa-2BV5BdcgHvh8mBr6SnIhW69sf4-2Fyf-2Fqgq3mOc5Tw8MunuE3kmR4Yyib5vo4O-2B4o-2BQAtQudrvKO66yYa5-2FmaT9PuaHtZzB6D9CfqzVukU0o00eVbJNyyL4PuRcK8yPPfK7ynqHIA-3D2kVQ_YwuJRA3z5CV5TcGP2xKodjRE92NzREK0JKZUfJi5U-2F576mw97kEpTheOZUb4G7kqTPHlLHo9dDQRCIssy6yolzEjEuhkmi0o1ZZzM6OlfvNhZ80ByuVNEaCR0f-2BFVzjihT1DcfdIPISL-2F2SitFXHuIfq25Kj3gG-2BdcSD5REPnzdTkKLX5EtaYwISIrMWk8gy-2F7bokzPVv86rqY-2FfAbGqHww-2BHEz1KgAL3A6uDJOjvLK5s8N-2F8Kf3jUphCLmXnfR2l8whuaMT5NsoMAlDvZ6BRRmtdv8xC1e1Vm4NDFis7zP6S9fdNJ3BGlIY5204ZPMtpuMl-2FyQEd4s-2B-2FV5qXyepxTniGGXZ65dR7sV51ti3VMq3AZdWDsGFMXR9UjqAvNcA8Xk7SUwc9VzC0EKR3ZZo6ypv-2FA4XNxAS1dl7JIHMK6U3ubLFgYWUDakL8yfqpOU9iwGnYqgo1C31k43fphQacfYtQ90lVQblgifibc-2BJaeZJ2OixaKBuFSic7xKe654jwwuJXrBANP4-2F78zUSZfq1lHx2vOr03BqGDSDpqxwTIcmWEeSPAiiWX5ujwwT0t0Pdq0dN2KX24Hvff2-2BoRWETdSVL3Dpl3qFf-2F2uuaIb5ugUKSQzk-2FkeQCYYxtXDCXkU')">Register Now!</gButton>
                    </div>
                </div>
            </Notification>
        </Reveal>
    </NotificationGroup>
</template>
<script>
    import { Notification, NotificationGroup } from '@progress/kendo-vue-notification';
    import { Reveal } from '@progress/kendo-vue-animation';
    import AppButton from '../AppButton.vue'

    let hideForSession = sessionStorage.getItem("hideForSession") || false;

    export default {
        components: {
            Notification,
            NotificationGroup,
            Reveal,
             gButton: AppButton
        },
        mounted() {
            window.setTimeout(() => {
                if (!hideForSession)
                    this.events = true;
            }, 500);

        },
        data() {
            return {
                events: false
            };
        },
        methods: {
            onClose(flag) {
                this[flag] = false;
                sessionStorage.setItem("hideForSession", true);
            },
            navigateTo(loc) {
                //this.$router.push(loc);
                this['events'] = false;
                window.open(loc, '_blank').focus();
            }
        },
        computed: {
            canRegister: function () {
                var regEnds = new Date(2024, 0, 23, 14);
                var now = new Date();
                return (now.getTime() < regEnds.getTime());
            }
        }
    };
</script>
<style scoped>
    .button.register-button {
        width: 200px;
    }

    .event-ad {
        max-width: 800px;
        background-color: #fff;
        border: solid 1px #aaa;
    }

        .event-ad .gift .text {
            font-size: 1.2em;
            margin-top: 1em;
            font-weight: bold;
            color: #601b85;
        }




        .event-ad >>> .k-notification-actions {
            align-self: self-start;
        }

        .event-ad >>> .k-notification {
            background-color: #fff;
            background: linear-gradient(219.87deg, rgba(251, 244, 234, 0.1) 0%, rgba(251, 240, 236, 1) 100%);
        }

        .event-ad .content {
            text-align: center;
        }

        .event-ad .when-where {
            margin-top: 1em;
        }

        .event-ad .orange div {
            color: #ED6A4A;
        }

    @media (max-width: 600px) {
        .event-ad .orange div {
            font-size: 0.7em;
        }

        .event-ad .content {
            font-size: 0.8em;
        }
    }
</style>