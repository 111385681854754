var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"highlight-color option2"},[_c('div',{staticClass:"why-gravitii"},[_c('div',{staticClass:"title"},[_vm._m(0),_vm._t("header",function(){return [_vm._m(1),_vm._m(2)]})],2),_c('div',[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('AppButton',{staticClass:"register",on:{"click":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.ButtonText))])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v("Why Gravitii? "),_c('a',{staticClass:"to-top k-icon k-i-arrow-up",attrs:{"href":"#home","aria-label":"Scroll to top"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v("What makes us "),_c('b',[_vm._v("different?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_vm._v(" Unlike traditional home care companies that tie you into contracts and service minimums, Gravitii gives you "),_c('b',[_vm._v("full control of your care without compromising safety and security")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row-wrapped"},[_c('div',{staticClass:"benefit"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/green-circle-check.png")}}),_vm._v(" "),_c('div',[_c('b',[_vm._v("Choose")]),_vm._v(" your provider")])]),_c('div',{staticClass:"benefit"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/green-circle-check.svg")}}),_vm._v(" "),_c('span',[_vm._v("Flexible to "),_c('b',[_vm._v("any budget")])])]),_c('div',{staticClass:"benefit"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/green-circle-check.svg")}}),_vm._v(" "),_c('span',[_vm._v("Enjoy "),_c('b',[_vm._v("top security features")])])]),_c('div',{staticClass:"benefit"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/green-circle-check.svg")}}),_vm._v(" "),_c('span',[_c('b',[_vm._v("Registered")]),_vm._v(" health care aides")])]),_c('div',{staticClass:"benefit"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/green-circle-check.svg")}}),_vm._v(" "),_c('span',[_c('b',[_vm._v("FREE")]),_vm._v(" 30-minute provider consult")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"highlight col"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/mapleleaf.svg")}}),_vm._v(" "),_c('b',[_vm._v("We are 100% Canadian")])]),_c('div',{staticClass:"col"},[_c('p',[_vm._v("This means "),_c('b',[_vm._v("your data is stored securely in Canada")]),_vm._v(".")]),_c('p',[_vm._v("If you call us, one of our team members in BC will pick up (not a robot or a person overseas).")]),_c('p',[_vm._v("We are your neighbours*, not your neighbors.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"highlight col"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/dont.svg")}}),_vm._v(" "),_c('b',[_vm._v("We don't believe in contracts")])]),_c('div',{staticClass:"col"},[_c('p',[_vm._v("Your care needs are unique and may not always be consistent, so why would you settle for services that tie you down?")]),_c('p',[_vm._v("Gravitii gives you the freedom to "),_c('b',[_vm._v("book what you need, when you need it")]),_vm._v(".")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"highlight col"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/green-shield-check.svg")}}),_vm._v(" "),_c('b',[_vm._v("We take safety very seriously")])]),_c('div',{staticClass:"col"},[_c('p',[_vm._v("All care providers are registered with the "),_c('b',[_vm._v("BC Care Aide & Community Health Worker Registry")]),_vm._v(".")]),_c('p',[_vm._v("You can choose care providers who have "),_c('b',[_vm._v("background checks, driver's abstracts, liability insurance, and WCB coverage")]),_vm._v(".")]),_c('p',[_vm._v("You can also view ratings and reviews from other people who have received in-home care services from providers on the platform.")])])])
}]

export { render, staticRenderFns }