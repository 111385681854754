<template>
    <section class="highlight-color option1 our-vision">
        <div class="flex-container">
            <div class="col">
                <h3>Our Vision <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h3>
                <h1><b>Transforming</b> traditional home <b>care</b></h1>
                <p>
                    At Gravitii, we understand first-hand the growing demand for home care support services, and the increasing challenges that the current healthcare system is facing trying to meet these needs
                </p>
                <p>
                    Our vision is to set a new standard of excellence in the home care industry, offering an enhanced experience for both the client and care provider that's just a few clicks away.
                </p>
            </div>
            <div class="col">
                <iframe class="video" width="100%" src="https://www.youtube.com/embed/7ANjzF1aIrY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>            
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        components: {
        },
        data: function () {
            return {
            };
        },
    };
</script>
<style>
    .our-vision .flex-container {
        display: flex;
        flex-direction: row;
        padding: 4em;
    }

    .our-vision .video {
        margin-left: 1.5em;
        width: 100%;
        border-radius: 1.3em;
        aspect-ratio: 1.77;
    }

    .our-vision .flex-container .col {
        flex: 50%;
    }

    @media (max-width: 769px) {
        .our-vision .flex-container {
            display: flex;
            flex-direction: column;
            padding: 4em;
        }

        .our-vision .flex-container .col {
            flex: 100%;
        }

        .our-vision .video {
            margin-left: 0;
            margin-top: 1em;
        }
    }

    @media (max-width: 600px) {
        .our-vision .flex-container {
            padding: 2em;
        }
    }
</style>