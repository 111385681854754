<template>
    <div class="info-tile">
        <img class="tile-icon" :src="require(`@/assets/icons/${icon}.svg`)" alt="tile icon" />
        <div class="tile-msg">
            <slot></slot>
        </div>
    </div>
</template>
<script>

    export default {
        props: ['icon'],
        components: {
        },
        data() {
            return {

            };
        }
    };
</script>
<style>
    .info-tile {
        display: flex;
        flex-direction: row;
        grid-column-gap: 1em;
        margin-right: 1em;
        align-items: flex-start;
    }

    .info-tile .tile-icon {
        flex: 100px;
        width: 100px;
        
    }

    .info-tile .tile-msg {
        flex: 100%;
    }

    .info-tile h1 {
        
        font-size: 1.5em;
        font-weight: bold;
    }

    @media (max-width: 600px) {
        .info-tile {
            display: block;
        }

        .info-tile .tile-icon {
            flex: 60px;
            width: 60px;
            float: left;
            margin-right: 1em;
        }
    }

</style>