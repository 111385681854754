<template>
    <span class="view">
        <PageHero />
        <BlogList />
    </span>
</template>

<script>
    import PageHero from '../components/PageHeroSmall.vue';
    import BlogList from "../components/blog/BlogList.vue";

    let meta = {
        type: "website",
        title: "Gravitii - Blog - Redefining home care... one connection at a time",
        description: "Gravitii modernizes the process of accessing care by leveraging technology to help deliver a more personalized, effective and timely home care experience for you or your loved ones.",
        image: window.location.origin + "/feature.jpg"
    };

    export default {
        name: "BlogView",
        components: {
            PageHero,
            BlogList
        },
        data() {
            return {
                
            };
        },
        metaInfo() {
            return {
                title: meta.title,
                meta: [
                    { name: 'description', content: meta.description },

                    { property: 'og:url', content: window.location.origin },
                    { property: 'og:type', content: meta.type },
                    { property: 'og:title', content: meta.title },
                    { property: 'og:description', content: meta.description },
                    { property: 'og:image', content: meta.image },

                    { property: 'twitter:url', content: window.location.origin },
                    { property: 'twitter:type', content: meta.type },
                    { property: 'twitter:title', content: meta.title },
                    { property: 'twitter:description', content: meta.description },
                    { property: 'twitter:image', content: meta.image },
                ]
            }
        }
    };
</script>
<style>
</style>