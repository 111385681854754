<template>
    <div>
        <h3>Frequently asked questions for Care Providers</h3>
        <br />
        <FAQQuestion :target="target" id="gravitii">
            <template #question>
                Why did you choose the name "Gravitii"?
            </template>
            <p>
                The name "Gravitii" is rich in symbolism and purpose, with each element reinforcing the essence of our company and its mission:
            </p>
            <ol>
                <li><strong>Grounded Commitment:</strong> The association of "Gravitii" with what keeps us grounded emphasizes our dedication to providing stable and reliable home care solutions. It reflects our unwavering commitment to helping individuals maintain their well-being.</li>
                <li><strong>Connecting Force:</strong> The concept of "Gravitii" as a force between objects of mass signifies our platform's role in creating connections between home care providers and those seeking care. It highlights the essential bond and support we aim to facilitate.</li>
                <li><strong>Serious Problem Solving:</strong> "Gravitii" conveys a sense of seriousness, which aligns perfectly with the critical issues in home care that our company seeks to address. It reflects our determination to provide effective solutions to significant home care challenges.</li>
                <li><strong>Edgy and Tech-Savvy:</strong> We feel Gravitii adds a modern and tech-savvy edge to our identity. We feel it positions us to be forward-thinking and innovative, which is what is needed to help provide better solutions for home care.</li>
            </ol>
        </FAQQuestion>
        <FAQQuestion :target="target" id="join">
            <template #question>
                How can I become a care provider with Gravitii?
            </template>
            <p>Becoming a care provider with Gravitii is easy. Simply visit our website, sign up to <a href="https://portal.gravitii.care/?action=register&who=provider">GIVE CARE</a>, and our team will guide you through the onboarding process of setting up your profile.</p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="qualifications">
            <template #question>
                What are the qualifications required to join as a care provider?
            </template>
            <p>
                Gravitii requires all care providers to be registered and in good standing with the <a target="_blank" href="https://www.cachwr.bc.ca/">BC Care Aide and Community Health Worker Registry (cachwr.bc.ca)</a> or the <a target="_blank" href="https://albertahcadirectory.com/">Alberta Health Care Aide Directory</a>.
                Care providers will be asked to upload a copy of their confirmation letter during the <a href="https://portal.gravitii.care/?action=register&who=provider">sign-up</a> process.
            </p>
            <p>
                Gravitii has also integrated with <a href="https://certn.co/" target="_blank">CERTN</a>, a background check platform, if you want to provide extra assurances to your clients.
                The background checks currently available include; a
                <a href="https://certn.co/criminal-record-checks/" target="_blank">criminal record check</a>,
                <a href="https://certn.co/identity-verification/" target="_blank">identity verification check</a>,
                <a href="https://certn.co/social-media-screening/" target="_blank">soft check</a> and
                <a href="https://certn.co/drivers-record-check/" target="_blank">driver's abstract</a>
                can all be added to your profile. (<strong>NOTE:</strong> CERTN does charge a fee for these checks.)
            </p>
            <p>
                Gravitii doesn't currently require additional certification, however, the Gravitii platform allows you to upload additional documentation
                (e.g. Liability Insurance, WCB) and certifications that allow potential clients to confirm your qualifications. Additional qualifications
                such as, Food Safe, First Aid, or specialized training may be important depending on the services that are being offered.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="registry">
            <template #question>
                How do I find my confirmation letter that I'm in good standing with the BC Care Aide and Community Health Worker registry?
            </template>
            <p>
                <ol>
                    <li>Log into the <a href="https://registrants.cachwr.bc.ca/public/login" target="_blank">BC Care Aide & Community Health Worker registry.</a></li>
                    <li>
                        Once logged in, click on the <strong>"Print Registration Confirmation"</strong> button to download your confirmation letter.
                        <br />
                        <img src="@/assets/faq/registry.png" style="width: 600px; max-width: 100%; aspect-ratio: 1.4599" alt="BC Care Aide & Community Health Worker registry" />
                    </li>
                    <li><a href="https://portal.gravitii.care" target="_blank">Login</a> to your care provider account on Gravitii.</li>
                    <li>
                        Click on <strong>"Profile"</strong> in the main menu, go to your <strong>"Skills"</strong> tab, select <strong>"BC Care Aide and Community Health Worker Registry"</strong>, click on <strong>"Select File"</strong>.
                        <br />
                        <img src="@/assets/faq/upload_certificate.png" style="width: 600px; max-width: 100%; aspect-ratio:1.4507;" alt="Upload Certifications" />
                    </li>
                    <li>Select your confirmation letter from your downloads file folder and click <strong>"Open"</strong>.</li>
                    <li>Click <strong>"Upload"</strong></li>
                </ol>
            </p>
            <p>
                Once you've completed this process, we will be automatically notified and can review your document to verify that you're in good standing.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="albertaRegistry">
            <template #question>
                How do I find my confirmation letter that I'm in good standing with the Alberta Health Care Aide Directory?
            </template>
            <p>
                <ol>
                    <li>Log into the <a href="https://abhca.alinityapp.com/Account/Login" target="_blank">Alberta Health Care Aides Directory portal.</a></li>
                    <li>
                        Once logged in, click on the <strong>"Confirmation"</strong> button to download your confirmation letter.
                        <br />
                        <img src="@/assets/faq/albertaRegistry.png" style="width: 495px; max-width: 100%; aspect-ratio: 2.2752" alt="Alberta Health Care Aides Directory" />
                    </li>
                    <li><a href="https://portal.gravitii.care" target="_blank">Login</a> to your care provider account on Gravitii.</li>
                    <li>
                        Click on <strong>"Profile"</strong> in the main menu, go to your <strong>"Skills"</strong> tab, select <strong>"Alberta Health Care Aide Directory"</strong>, click on <strong>"Select File"</strong>.
                        <br />
                        <img src="@/assets/faq/upload_certificate.png" style="width: 600px; max-width: 100%; aspect-ratio:1.4480;" alt="Upload Certifications" />
                    </li>
                    <li>Select your confirmation letter from your downloads file folder and click <strong>"Open"</strong>.</li>
                    <li>Click <strong>"Upload"</strong></li>
                </ol>
            </p>
            <p>
                Once you've completed this process, we will be automatically notified and can review your document to verify that you're in good standing.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="matching">
            <template #question>
                How does the matching process work with clients?
            </template>
            <p>
                Our platform uses a proprietary matching algorithm to connect you with clients based on care needs, location, availability, and preferences.
                Gravitii highlights matches that best meet these criteria but still allows the client to browse through the full list of care providers in their area.
                It's designed for a personalized experience.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="hiring">
            <template #question>
                Is Gravitii hiring Care Providers?
            </template>
            <p>
                Gravitii is a technology company that offers the Gravitii platform as a set of tools to help you run your own home care business.
                Gravitii focuses on delivering technology that helps clients find you and provides you with tools to manage your client base while allowing you to focus on
                delivering your best services. Gravitii is always interested in having new care providers <a href="https://portal.gravitii.care/?action=register&who=provider">sign-up</a> and we encourage you to get started today.
                Gravitii does not employ Care Providers. All Care Providers are self-employed.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="benefits">
            <template #question>
                Does Gravitii offer employee benefits?
            </template>
            <p>
                All care providers on the Gravitii platform are self-employed and do not work for Gravitii.  Traditional employee benefits are not offered by Gravitii.  However, there are benefits to being self-employed that you can take advantage.
                Some of these benefits include:
            </p>
            <ol>
                <li>Ability to define your own rates and earn more.</li>
                <li>Ability to set your own schedule.  You decide when and how much you want to be available.</li>
                <li>Ability to determine which clients you work with.  You always have the option to decline a booking request if the client isn't right for you.</li>
                <li>You may be eligible to write-off some of your expenses.  Please talk to your accountant to develop a tax strategy that works for you.</li>
            </ol>
        </FAQQuestion>

        <FAQQuestion :target="target" id="rates">
            <template #question>
                How much will I get paid?
            </template>
            <p>
                Gravitii allows you to define your own rates.  We encourage you to review the average rates that are charged for home care in the region that you are
                offering services to ensure that you remain competitive.  In British Columbia, the average hourly cost for home care is approximately $40 per hour.
            </p>
            <p>
                The services that you offer, your experience, and your specialized skills are some of the things that can help determine what rates you should charge.  The Gravitii platform allows you to define multiple service rates that best match the services that you offer to your clients.  Your rates are entirely up to you.

            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="gst">
            <template #question>
                Do I need to collect GST?
            </template>
            <p>
                You might need to collect GST for your services.  It's best to talk with your accountant to discuss your unique business needs and to determine if you are required to collect GST.
            </p>
            <p>
                There are some general rules that can help guide you on this topic.  Generally, if you earn less than $30,000 per year of self employment income you are exempt from charging GST.
                You can read more about this here: <a target="_blank" href="https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/gst-hst-businesses/when-register-charge.html">When to register for and start charging the GST/HST - Canada.ca</a>
                and <a target="_blank" href="https://www.canada.ca/en/revenue-agency/services/forms-publications/publications/gi-166/application-gst-hst-home-care-services.html">Application of the GST/HST to Home Care Services - Canada.ca</a>.

            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="insurance">
            <template #question>
                Do I need to be insured?
            </template>
            <p>
                Gravitii recommends that care providers carry sufficient insurance to protect yourself and your business. Every provider will have unique
                insurance needs so you should talk to your insurance professional to find out what's best for you. Some things to ask about are liability
                insurance, auto insurance (if you are driving), and professional associate insurance. Gravitii also recommends that you hold workers
                compensation insurance. In British Columbia, this is done through <a target="_blank" href="https://www.worksafebc.com/en">WorkSafeBC</a>. In Alberta, this is done through <a target="_blank" href="https://www.wcb.ab.ca/">WCB-Alberta</a>.
            </p>
            <p>
                If you have liability insurance and/or WCB insurance, upload these documents to your profile on Gravitii to build trust with prospective clients.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="issues">
            <template #question>
                How can I address concerns or issues with clients or the platform?
            </template>
            <p>
                Gravitii encourages care providers to discuss their concerns or issues with the client and/or family caregiver.  Our <a href="mailto:support@gravitii.care">support team</a> is also
                available to assist with any concerns or call us at <a href="tel:1-778-946-0075">1-778-946-0075</a>. We also have a feedback system to improve the overall experience for care providers and clients.
            </p>
        </FAQQuestion>
        <FAQQuestion :target="target" id="safety">
            <template #question>
                Is Gravitii committed to client and care provider safety?
            </template>
            <p>
                Ensuring safety is a top priority for home care providers to provide the best possible care to their clients.
            </p>
            <p>
                Here are some essential steps and considerations to ensure safety when delivering home care services:
            </p>
            <ul>
                <li><strong>Client Assessment</strong> - Begin with a thorough assessment of the client's needs, medical conditions, and safety risks. This information forms the foundation for a personalized care plan. Review the client's profile entirely, so you understand their health conditions and care needs.</li>
                <li><strong>Safe Environment</strong> - Regularly assess the home environment for hazards. Ensure that walkways are clear, rugs are secure, and the home is well-lit to prevent falls.</li>
                <li><strong>Infection Control</strong> - Implement strict infection control measures, especially in the context of COVID-19. Regular handwashing, mask-wearing, and maintaining a clean environment are crucial.</li>
                <li><strong>Medication Management</strong> - If you've been delegated to administer medications, follow precise medication management protocols, including verifying the medication, dosage, and timing.</li>
                <li><strong>Mobility and Transfers</strong> - Properly assist with mobility and transfers to prevent injuries. Use appropriate assistive devices like gait belts, wheelchairs, or bedrails when necessary.</li>
                <li><strong>Fall Prevention</strong> - Be vigilant about fall prevention. Use grab bars in bathrooms, non-slip mats, and ensure clients have proper footwear. Encourage strength-building exercises.</li>
                <li><strong>Emergency Preparedness</strong> - Know how to respond in emergencies. This includes having a clear plan for medical emergencies and maintaining knowledge of CPR and first aid.</li>
                <li><strong>Proper Body Mechanics</strong> - Learn and practice proper body mechanics to avoid injury when lifting or assisting clients. Avoid overexertion and use assistive devices when appropriate.</li>
                <li><strong>Communication</strong> - Maintain clear and open communication with clients and their families. Ensure that everyone understands the care plan and any changes in the client's condition.</li>
                <li><strong>Regular Check-Ins</strong> - Schedule regular check-ins and assessments to monitor changes in the client's health and well-being. Early detection of health issues can prevent emergencies.</li>
                <li><strong>Documentation</strong> - Keep thorough and accurate records of the care provided, including medications administered, vital signs, and any changes in the client's condition.</li>
                <li><strong>Training and Certification</strong> - Stay up to date with training, certifications, and continuing education in home care, first aid, and safety practices.</li>
                <li><strong>Boundaries</strong> - Maintain professional boundaries with clients to ensure their emotional and physical safety. Report any concerns or signs of abuse or neglect.</li>
                <li><strong>Respect for Client's Rights</strong> - Always respect the client's autonomy and rights, including the right to privacy and informed consent.</li>
                <li><strong>Self-Care</strong> - Ensure your own well-being by practicing self-care. This helps prevent burnout and allows you to provide better care.</li>
            </ul>
            <p>
                Remember that each client may have unique safety needs, so adapt your approach accordingly. Effective communication, a strong knowledge of safety protocols, and a commitment to providing high-quality care are essential for ensuring the safety and well-being of clients in a home care setting.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="traditional">
            <template #question>
                What sets Gravitii apart from other home care companies?
            </template>
            <p>Gravitii is a technology company that provides a platform that supports and enhances the process of finding and delivering home care.  Gravitii's goal is to empower clients to find and receive home care that best suits their needs while allowing care providers to focus on delivering their services. </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="paid">
            <template #question>
                How do I get paid?
            </template>
            <p>Once you've completed your appointment, the fee you charge will be added to your current balance that displays on your dashboard. Gravitii will directly deposit this balance to you daily. However, it will take 7-9 days to show up in your bank account. </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="banking">
            <template #question>
                Is my personal and banking information secure?
            </template>
            <p>
                Security is a top priority for us. We have implemented robust measures to ensure the safety of your personal and banking information. Our application employs state-of-the-art encryption techniques to protect data during transmission and storage. We also adhere to industry best practices and regulatory standards for data security.
            </p>
            <p>
                Rest assured, your personal and banking information is treated with the utmost care and confidentiality. Your banking information is stored and managed by <a target="_blank" href="https://stripe.com">Stripe</a>; you can find more information about their data security <a target="_blank" href="https://stripe.com/docs/security">here</a>. Your trust is important to us, and we are committed to maintaining the highest levels of security to safeguard your data.
            </p>
        </FAQQuestion>


        <FAQSpamQuestion id="spam">
        </FAQSpamQuestion>

    </div>
</template>
<script>
    import FAQQuestion from './FAQQuestion.vue';
    import FAQSpamQuestion from './FAQSpamQuestion.vue';

    export default {
        components: {
            FAQQuestion,
            FAQSpamQuestion
        },
        data() {
            return {

            };
        },
        props: {
            target: String
        },
        methods: {
        }
    };
</script>
<style scoped>
</style>