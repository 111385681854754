<template>
    <section>
        <div class="about-us">
            <div>
                <h3>About Us <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h3>
                <h1>
                    <b>Meet</b> the team
                </h1>
            </div>
            <div class="flex-container">
                <div class="col">
                    <img class="head-shot" src="@/assets/kevin.jpg" alt="Kevin Wade, CMO and Co-founder" />
                    <h2>Dr. Kevin Wade</h2>
                    Kevin is a palliative care
                    physician who has dedicated his
                    life to helping those in need. Dr.
                    Wade's commitment to providing
                    superior care has propelled him
                    to seek out new opportunities,
                    leveraging his expertise to help
                    transform the home care industry.
                </div>
                <div class="col">
                    <img class="head-shot" src="@/assets/jeff.jpg" alt="Jeff Van Dyk, CEO and Co-founder" />
                    <h2>Jeff Van Dyk</h2>
                    <p>
                    Jeff has a proven track record as
                    an accomplished entrepreneur
                    with over 25 years of experience
                    in software development in the
                    healthcare and wellness
                    industries. Jeff is passionate
                    about technology and the
                    impact that innovative software
                    can have on people.
                        </p>
                    <p>
                        When not working on improving access to home care for Canadians, Jeff enjoys teaching karate at his local dojo, mountain biking, skiing, and camping with his family.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        components: {
        },
        data: function () {
            return {
            };
        },
    };
</script>
<style>
    .about-us {
        padding: 4em;
    }

    .about-us .head-shot {
        height: 300px;
        float:left;
        margin-right: 1em;
        border-radius: 25%;
    }

    .about-us .flex-container {
        display: flex;
        flex-direction: row;
        gap: 4em;
        margin-top: 2em;
    }

    .about-us .col {
        flex: 50%;
    }

    @media (max-width: 1025px) {
        .about-us .head-shot {
            float: none;
            margin-right: 0;
        }
    }

    @media (max-width: 600px) {
        .about-us {
            padding: 2em;
        }

        .about-us .flex-container {
            display: flex;
            flex-direction: column;
            gap: 4em;
            margin-top: 2em;
        }

        .about-us .col {
            flex: 100%;
        }
    }

</style>