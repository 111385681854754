<template>
    <section class="blog padded">
        <h1>Blog</h1>
        <BlogPost v-for='post in posts' :key='post.id' :post="post" />
        <AppButton v-if="nextPageToken" @click="fetchPosts()">More</AppButton>
    </section>
</template>
<script>
    import AppButton from '../AppButton.vue';
    import BlogPost from './BlogPost.vue';
    
    export default {
        components: {
            AppButton,
            BlogPost
        },
        data() {
            return {
                nextPageToken: "",
                posts: []
            };
        },
        methods: {
            async fetchPosts() {
                let url = `https://blogger.googleapis.com/v3/blogs/${this.appConfig.$blogger.blogId}/posts?maxResults=3&key=${this.appConfig.$blogger.key}`;
                if (this.nextPageToken)
                    url = url + `&pageToken=${this.nextPageToken}`;

                const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
                };

                await fetch(url, requestOptions)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        console.log("Results", data);
                        this.nextPageToken = data.nextPageToken;
                        this.posts = this.posts.concat(data.items);
                    });
            }
        },
        mounted() {
            this.fetchPosts();
        }
    };
</script>
<style scoped>
    .blog h1 {
        padding-left: 0.5em;
        padding-bottom: 1em;
    }
</style>