<template>
    <section>

        <div class="give-care">
            <div>
                <h3>Give Care <a href="#home" class="to-top k-icon k-i-arrow-up" aria-label="Scroll to top"></a></h3>
                <h1>
                    Gravitii helps facilitate a
                    <b>personal connection</b> with
                    <b>patients</b> near you
                </h1>
            </div>
            <PlatformActions class="platform-actions" who="provider"></PlatformActions>
            <div class="flex-container">
                <div class="col">
                    <p>
                        At Gravitii, we believe that caring for others is more
                        than just a business, it's a human experience. As a
                        technology company that connects home care
                        providers with patients, we are passionate about
                        enhancing the experience of providing care in
                        people's homes.
                    </p>
                    <p>
                        Home care providers play a vital role in ensuring the
                        well-being of their patients. Unfortunately, there are a
                        number of challenges that home care providers face,
                        including restrictive policies, insufficient scheduling,
                        and limited autonomy to provide personalized care.
                    </p>
                    <p>
                        We understand these unique challenges and are
                        committed to providing innovative solutions to help
                        care providers deliver the highest quality of care to
                        their patients.
                    </p>
                </div>
                <div class="col bullets">
                    <div class="flex-container">
                        <gInfoTile icon="connect">
                            <h1>
                                Connect with your ideal
                                patient
                            </h1>
                            <p>
                                Match with patients who are the
                                best fit for your skills, availability
                                and location so you can deliver
                                exceptional care that is best suited
                                to each patient's unique needs.
                            </p>
                        </gInfoTile>
                        <gInfoTile icon="freedom">
                            <h1>Freedom to choose</h1>
                            <p>
                                Be in control of your career and your
                                care. Our platform empowers you to
                                set your own schedule, travel time,
                                fees and services, allowing you to
                                focus on what you do best.
                            </p>
                        </gInfoTile>
                        <gInfoTile icon="earn">
                            <h1>Earn more</h1>
                            <p>
                                Gravitii effectively cuts out the
                                middleman, giving you the
                                opportunity to earn a higher hourly
                                wage compared to the industry
                                average.
                            </p>
                        </gInfoTile>
                        <gInfoTile icon="build">
                            <h1>Build meaningful connections</h1>
                            <p>
                                We believe that home care should be
                                about enhancing the overall care
                                experience by building meaningful
                                connections between you and your
                                patients.
                            </p>
                        </gInfoTile>
                        <div class="sign-up">
                            <img class="prov-img" src="@/assets/careaide.png" alt="Care Provider" />
                            <h2 class="free">Sign up for <strong>free</strong> to run <strong>your home care</strong> business!</h2>
                            <h3>
                                I'm a home care provider,
                                <a href="https://portal.gravitii.care/?who=provider&action=register">sign me up</a>!
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <gNewsletter list="provider" :visible="showWindow" @close="toggleWindow"></gNewsletter>
    </section>
</template>
<script>
    import AppInfoTile from './AppInfoTile.vue';
    import WindowNewsletter from './WindowNewsletter.vue';
    import PlatformActions from './PlatformActions.vue';

    let showWindow = false;

    export default {
        components: {
            gInfoTile: AppInfoTile,
            gNewsletter: WindowNewsletter,
            PlatformActions: PlatformActions
        },
        data: function () {
            return {
                showWindow
            };
        },
        methods: {
            toggleWindow: function () {
                this.showWindow = !this.showWindow;
            }
        }
    };
</script>
<style>
    .give-care {
        padding: 4em;
        background-image: url("~@/assets/careaide.png");
        background-repeat: no-repeat;
        background-position: 115% 120%;
    }

        .give-care img {
            width: 300px;
            max-width: 100%;
        }

        .give-care .prov-img {
            display: none;
        }

        .give-care .flex-container {
            display: flex;
            flex-wrap: wrap;
            gap: 2em;
            align-content: center;
            align-items: center;
        }

            .give-care .flex-container .col {
                flex: 100%;
            }

                .give-care .flex-container .col.bullets {
                    flex: 50%;
                    max-width: 63%;
                }

        .give-care .free {
            font-style: normal;
            font-weight: 200;
            line-height: 1.02em;
            letter-spacing: -0.03em;
            color: #133053;
        }

            .give-care .free strong {
                font-weight: 900;
                color: #e67737;
            }

    .info-tile {
        align-self: center;
        width: 100%;
    }

    @media (max-width: 1025px) {
        .give-care .flex-container .col.bullets {
            flex: 80%;
            max-width: 70%;
        }

        .give-care {
            background-position: 120% 100%;
            background-size: 50%;
        }
    }

    @media (max-width: 769px) {
        .give-care {
            background-position: 115% 105%;
            background-size: 50%;
        }
    }

    @media (max-width: 600px) {

        .give-care .prov-img {
            display: inherit;
        }

        .give-care img {
            display: inherit;
            width: 300px;
            max-width: 100%;
        }

        .give-care {
            padding: 2em;
            background-image: none;
        }

            .give-care .flex-container .col.bullets {
                flex: 100%;
                max-width: 100%;
            }


        .sign-up {
            width: 100%;
            text-align: center;
        }
    }
</style>