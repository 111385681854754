<template>
    <div class="container">
        <router-view></router-view>
        <PageFooter id="contactus" />
    </div>
</template>

<script>
    import '@progress/kendo-theme-material/dist/all.css';
    import './styles.css'

    import PageFooter from './components/PageFooter.vue';

    export default {
        name: "App",
        components: {
            PageFooter
        },
        data() {
            return {
            };
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("ref")) {
                const referralCode = urlParams.get("ref") || "";
                if (referralCode != "") {
                    window.location.href = `https://portal.gravitii.care/?action=register&who=provider&referral=${referralCode}`;
                }
            }
        }
    };
</script>
<style>
</style>