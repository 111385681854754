import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import App from './App.vue';
import Home from './views/HomeView.vue';
import Blog from './views/BlogView.vue';
import Careers from './views/CareersView.vue';
import FAQ from './views/FAQView.vue';
import Events from './views/EventsView.vue';
import Terms from './views/TermsView.vue';
import GrapevineLandingPage from './views/landingpages/GrapevineLandingPageView.vue';
import JFSLandingPage from './views/landingpages/JFSLandingPageView.vue';
import PickleballLandingPage from './views/landingpages/PickleballLandingPageView.vue';
import VancouverPickleballLandingPageView from './views/landingpages/VancouverPickleballLandingPageView.vue';
import KelownaPickleballLandingPageView from './views/landingpages/KelownaPickleballLandingPageView.vue';
import SSSBCLandingPageView from './views/landingpages/SSSBCLandingPageView.vue';
import TermsTestimonialContest from './views/TermsTestimonialContest.vue';
import Privacy from './views/PrivacyView.vue';
import FacebookDataDeletion from './views/FacebookDataDeletionView.vue';
import { config } from './config';
import { extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';

// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize('en', en);

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.prototype.appConfig = config;

Vue.config.productionTip = false;

const routes = [

    { path: '*', component: Home },
    { path: '/faq', component: FAQ },
    { path: '/faq/:who', component: FAQ },
    { path: '/faq/:who/:id', component: FAQ },
    { path: '/blog', component: Blog },
    { path: '/careers', component: Careers },
    { path: '/event*', component: Events },
    { path: '/privacy', component: Privacy },
    { path: '/terms', component: Terms },
    { path: '/contest/testimonial', component: TermsTestimonialContest },
    { path: '/facebook-data-deletion', component: FacebookDataDeletion },
    { path: '/grapevine', component: GrapevineLandingPage },
    { path: '/jfs', component: JFSLandingPage },
    { path: '/pickleball', component: PickleballLandingPage },
    { path: '/vancouverpickleball', component: VancouverPickleballLandingPageView },
    { path: '/kelownapickleball', component: KelownaPickleballLandingPageView },
    { path: '/sssbc', component: SSSBCLandingPageView },
];

const router = new VueRouter({
    routes,
    scrollBehavior(to/*, from, savedPosition*/) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'
                // , offset: { x: 0, y: 10 }
            }
        } else {
            return { x: 0, y: 0 }
        }
    }
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
