<template>
    <div>
        <h3>Frequently asked questions for Patients and Care Givers</h3>
        <br />
        <FAQQuestion :target="target" id="gravitii">
            <template #question>
                What is Gravitii.care, and how can it benefit me or my loved ones?
            </template>
            <p>Gravitii.care is a platform that connects you with compassionate, professional home care providers. We offer a personalized home care solution, ensuring you or your loved ones receive the care they need.</p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="types">
            <template #question>
                What types of home care services can I access through Gravitii.care?
            </template>
            <p>
                Gravitii requires all care providers to be registered and in good standing with the <a target="_blank" href="https://www.cachwr.bc.ca/">BC Care Aide and Community Health Worker Registry (cachwr.bc.ca)</a> or the <a target="_blank" href="https://albertahcadirectory.com/">Alberta Health Care Aide Directory</a>.
            </p>
            <p>
                Health (Home) Care Aides, HCAs, learn a variety of skills to provide quality care to clients in a home setting. These skills typically cover the supportive aspects of medical and non-medical care, including:
            </p>
            <ul>
                <li><strong>Personal Care Assistance</strong> - HCAs help clients with activities of daily living (ADLs), including bathing, dressing, grooming, and toileting.</li>
                <li><strong>Mobility Assistance</strong> - They assist clients with mobility, transfers, and exercises to improve strength and flexibility.</li>
                <li><strong>Medication Management</strong> - HCAs may be delegated to administer medications, track doses, and ensure clients take their medications as prescribed.</li>
                <li><strong>Vital Sign Monitoring</strong> - They learn how to measure and record vital signs, such as blood pressure, pulse, temperature, and respiratory rate.</li>
                <li><strong>Nutrition and Meal Preparation</strong> - HCAs prepare meals, assist with feeding, and help clients follow dietary restrictions or special diets.</li>
                <li><strong>Hygiene and Infection Control</strong> - They are trained in maintaining a clean and safe environment, following infection control protocols, and preventing the spread of illnesses.</li>
                <li><strong>CPR and First Aid</strong> - HCAs often have basic training in cardiopulmonary resuscitation (CPR) and first aid to respond to emergencies.</li>
                <li><strong>Record Keeping</strong> - They maintain accurate records of care provided, including medications administered and changes in the client's condition.</li>
                <li><strong>Communication Skills</strong> - HCAs learn effective communication to understand and meet clients' needs, provide emotional support, and report any concerns.</li>
                <li><strong>Cognitive Support</strong> - For clients with cognitive impairments, HCAs provide support and engage in activities that stimulate memory and cognitive function.</li>
                <li><strong>Dementia Care</strong> - Specialized training in dementia care equips HCAs to care for clients with Alzheimer's or other forms of dementia.</li>
                <li><strong>Client Safety</strong> - They ensure the safety of clients by identifying and mitigating hazards in the home environment.</li>
                <li><strong>Palliative Care</strong> - Training in palliative care enables HCAs to provide comfort and support for clients with serious illnesses at the end of life.</li>
                <li><strong>Pediatric Care</strong> - Specialized skills are necessary for caring for children, including understanding their unique developmental needs.</li>
                <li><strong>Mental Health Support</strong> - They may be trained to support clients with mental health challenges and provide emotional support.</li>
                <li><strong>Cultural Competency</strong> - Understanding and respecting the diverse cultural backgrounds of clients is crucial for providing culturally sensitive care.</li>
                <li><strong>Proper Body Mechanics</strong> - To prevent injuries, HCAs learn how to lift, move, and assist clients safely using proper body mechanics.</li>
                <li><strong>Documentation</strong> - Keeping detailed and accurate records is essential for tracking care and sharing information with other healthcare professionals.</li>
            </ul>
            <p>
                The specific skills and training required can vary based on regulations and the individual needs of clients. HCAs often undergo formal training programs, which may include classroom instruction and hands-on clinical experience. Continuous education and ongoing training help HCAs stay current with best practices in home care.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="cost">
            <template #question>
                How much does it cost?
            </template>
            <p>
                Care providers on the Gravitii platform set their own rates so the cost will vary depending on which care provider you choose and what services they offer.
                The average hourly cost for home care in British Columbia is approximately $40/hour.  Rates may be determined by demand, experience, or specialty qualifications.
                Gravitii encourages home care providers to offer competitive rates. In addition to the Provider's fee, Gravitii charges a 10% service fee plus applicable taxes
                and Stripe charges a small service fee for processing payment. The total cost is visible to you before sending the booking request.
                Your credit card isn't charged until a care provider confirms your booking and completes the services you requested.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="available">
            <template #question>
                Is Gravitii.care available in my area?
            </template>
            <p>
                Gravitii is currently operating in British Columbia and Alberta.  We started close to home in the Central Okanagan and the Greater Victoria regions and new care providers are signing on everyday across British Columbia and Alberta.
                Please <a href="https://portal.gravitii.care/?action=register&who=patient">create an account</a> or <a href="/#/#contactus">contact</a> our team to confirm whether we serve your area.

            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="find">
            <template #question>
                How do I find a suitable care provider through Gravitii.care?
            </template>
            <p>It's easy! <a href="https://portal.gravitii.care/?action=register&who=patient">Sign up</a> on our website, and we'll match you with the right care providers based on your unique needs, location, and preferences.  It's free to sign up and view who is available to provide care in your area.</p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="needs">
            <template #question>
                How will a care provider know what I need for care?
            </template>
            <p>
                During the registration process Gravitii will walk you (or your family caregiver) through a needs assessment to help you define your (or your loved one's) needs and preferences. Gravitii's algorithm will match you to care providers that best suit the needs that you have defined. Once you've sent a booking request to a care provider, that care provider will have access to view your profile information, including your needs assessment, health conditions, and personal preferences. We also encourage you (or your family caregiver) to book an initial free phone consultation with your chosen provider(s) to discuss your (or your family member's) care needs before booking a paid service.
            </p>
        </FAQQuestion>


        <FAQQuestion :target="target" id="trust">
            <template #question>
                How can I trust the care providers available on your platform?
            </template>
            <p>
                Ensuring that a home care provider is trustworthy is essential to the safety and well-being of the patient. Although care providers are required to upload a
                confirmation letter that they are in good standing with the <a href="https://www.cachwr.bc.ca/" target="_blank">BC Care Aide and Community Health Worker Registry</a> or the <a href="https://albertahcadirectory.com/" target="_blank">Alberta Health Care Aide Directory</a>,
                here are several steps an individual can take to verify the trustworthiness of a home care provider:
            </p>
            <ul>
                <li>
                    <strong>Background Checks</strong> - Gravitii has integrated with <a href="https://certn.co/" target="_blank">CERTN</a>, a background check platform,
                    so you can now see what background checks a provider has completed next to their name in your search results on Gravitii.
                    This includes a
                    <a href="https://certn.co/criminal-record-checks/" target="_blank">criminal record check</a>,
                    <a href="https://certn.co/identity-verification/" target="_blank">identity verification check</a>,
                    <a href="https://certn.co/social-media-screening/" target="_blank">soft check</a> and
                    <a href="https://certn.co/drivers-record-check/" target="_blank">driver's abstract</a>. If they don't display
                    the background check verification badge <img class="badge" alt="Background Checks Badge" src="@/assets/icons/shield-check.png" /> , you might wish to ask them directly if they would be willing
                    to have a background check done.
                </li>
                <li>
                    <strong>Licensing and Certification</strong> - Verify that the home care provider is appropriately licensed, certified, and qualified to provide
                    the specific level of care required. Different levels of care may require different certifications. Look for the verification badge
                    <img class="badge" alt="Home/Health Care Aide Registry Badge" src="@/assets/icons/circle-single-check.png" />
                    that they're in good standing with the <a href="https://www.cachwr.bc.ca/" target="_blank">BC Care Aide registry</a> or <a href="https://albertahcadirectory.com/" target="_blank">Alberta Health Care Aide Directory</a> next to their name in your search results on Gravitii.
                </li>
                <li><strong>References</strong> - Request and contact references from the home care provider and ask about their previous experiences with clients. This can help you gauge their reliability and professionalism.</li>
                <li><strong>Experience and Training</strong> - Inquire about the provider's experience and training. A trustworthy provider should have relevant experience and up-to-date training in the specific care needs of the individual.</li>
                <li><strong>Reviews and Ratings</strong> - Read reviews and ratings of the provider on Gravitii. While not the sole basis for trustworthiness, positive reviews can be a good sign.</li>
                <li>
                    <strong>Insurance and Bonding</strong> - Ensure that the provider has the necessary insurance and bonding in case of accidents or incidents that may occur during care. You can now see whether a provider has provided proof of liability insurance and WCB in your search results on Gravitii. Look for the verification badge
                    <img class="badge" alt="Insurance Badge" src="@/assets/icons/circle-double-check.png" /> next to their name.
                </li>
                <li><strong>Transparency</strong> - The provider should be transparent about their services, fees, and any potential additional costs.</li>
                <li><strong>In-Person or Telephone Meeting</strong> - Whenever possible, meet the home care provider in person or on the telephone before they start providing care. This allows you to assess their demeanor, professionalism, and compatibility with the individual who needs care.  All care providers on Gravitii should offer a free 30-minute phone consultation service so that you can get to know them.</li>
                <li><strong>Care Plan</strong> - Discuss and review the care plan with the provider, ensuring that they understand the individual's specific needs and preferences.</li>
                <li><strong>Communication</strong> - A trustworthy provider should maintain open and effective communication with the person who needs care and their family. They should listen to concerns, answer questions, and provide regular updates on the individual's condition.</li>
                <li><strong>Boundary Respect</strong> - A trustworthy provider will maintain professional boundaries and respect the individual's privacy and dignity at all times.</li>
                <li><strong>Quality of Care</strong> - Monitor the quality of care provided and be vigilant about any signs of neglect, abuse, or inadequate care.</li>
                <li><strong>Family Involvement</strong> - Involve family members or a trusted advocate in the selection process, care planning, and monitoring to provide an extra layer of protection and accountability.</li>
                <li><strong>Trust Your Instincts</strong> - If something feels off or if you have any doubts, trust your instincts, and consider seeking another provider.</li>
            </ul>
            <p>
                Ultimately, trust is built over time through consistent, quality care and communication. Regularly reassess the provider's performance and remain vigilant to ensure the continued trustworthiness of the home care provider.
            </p>
        </FAQQuestion>


        <FAQQuestion :target="target" id="privacy">
            <template #question>
                Is Gravitii.care committed to patient data privacy and security?
            </template>
            <p>
                Home care patient data privacy and security are of utmost importance to ensure that sensitive health information and personal data are protected.
            </p>
            <p>
                Here are a few key considerations that Gravitii employs for safeguarding patient privacy and security:
            </p>
            <ul>
                <li><strong>Compliance with Regulations</strong> - Gravitii complies with all relevant privacy and security regulations, such as the Health Insurance Portability and Accountability Act (HIPAA) in the United States or the Personal Information Protection and Electronic Documents Act (PIPEDA) in Canada.</li>
                <li><strong>Consent</strong> - Consent is obtained from the patient for the collection, use, and disclosure of their health information.</li>
                <li><strong>Data Encryption</strong> - Strong encryption at rest and in transit is required for electronic health records and communications to protect patient information from unauthorized access.</li>
                <li><strong>Password Protection</strong> - Strong password policies are enforced for any electronic systems or devices used to store or access patient information.</li>
                <li><strong>Access Control</strong> - Access to patient information is limited to authorized personnel only. Gravitii employs the use of access controls and unique user IDs to restrict data access.</li>
                <li><strong>Training</strong> - Gravitii provides ongoing training for staff on privacy and security policies, as well as the proper handling of patient information.</li>
                <li><strong>Secure Communication</strong> - Secure communication channels are used for discussing patient information and avoid discussing sensitive information in public or in the presence of others who do not need to know.</li>
                <li><strong>Data Backups</strong> - Regular back ups of electronic health records and other important data to prevent loss due to system failures or data breaches.</li>
            </ul>

        </FAQQuestion>

        <FAQQuestion :target="target" id="secure">
            <template #question>
                Is my credit card information secure?
            </template>
            <p>
                Security is a top priority for us. We have implemented robust measures to ensure the safety of your personal and banking information. Our application employs state-of-the-art encryption techniques to protect data during transmission and storage. We also adhere to industry best practices and regulatory standards for data security.
            </p>
            <p>
                Rest assured, your personal and banking information is treated with the utmost care and confidentiality. Your banking information is stored and managed by <a target="_blank" href="https://stripe.com">Stripe</a>; you can find more information about their data security <a target="_blank" href="https://stripe.com/docs/security">here</a>. Your trust is important to us, and we are committed to maintaining the highest levels of security to safeguard your data.
            </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="minimum">
            <template #question>
                Is there a minimum # of hours I need to purchase each week?
            </template>
            <p>Gravitii doesn't require a minimum number of hours.  However, some care providers may require a minimum duration when booking.  </p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="getstarted">
            <template #question>
                How can I get started and experience the benefits of Gravitii.care for my loved ones or myself?
            </template>
            <p>To get started, visit our website, and <a href="https://portal.gravitii.care/?action=register&who=patient">sign up</a>. We'll guide you through the registration process and match you with care providers that can meet your unique needs.</p>
        </FAQQuestion>

        <FAQQuestion :target="target" id="name">
            <template #question>
                Why did you choose the name "Gravitii"?
            </template>
            <p>
                The name "Gravitii" is rich in symbolism and purpose, with each element reinforcing the essence of our company and its mission:
            </p>
            <ol>
                <li><strong>Grounded Commitment:</strong> The association of "Gravitii" with what keeps us grounded emphasizes our dedication to providing stable and reliable home care solutions. It reflects our unwavering commitment to helping individuals maintain their well-being.</li>
                <li><strong>Connecting Force:</strong> The concept of "Gravitii" as a force between objects of mass signifies our platform's role in creating connections between home care providers and those seeking care. It highlights the essential bond and support we aim to facilitate.</li>
                <li><strong>Serious Problem Solving:</strong> "Gravitii" conveys a sense of seriousness, which aligns perfectly with the critical issues in home care that our company seeks to address. It reflects our determination to provide effective solutions to significant home care challenges.</li>
                <li><strong>Edgy and Tech-Savvy:</strong> We feel Gravitii adds a modern and tech-savvy edge to our identity. We feel it positions us to be forward-thinking and innovative, which is what is needed to help provide better solutions for home care.</li>
            </ol>
        </FAQQuestion>

        <FAQQuestion :target="target" id="concerns">
            <template #question>
                How can I address concerns or issues with my care provider or the platform?
            </template>
            <p>
                We encourage family caregivers and those receiving care to speak with their care provider to address any concerns with their care services.  We also have a <a href="mailto:support@gravitii.care">support team</a> available to assist you and we welcome feedback to help us improve our platform service.
            </p>
        </FAQQuestion>

        <FAQSpamQuestion id="spam">
        </FAQSpamQuestion>


    </div>
</template>
<script>
    import FAQQuestion from './FAQQuestion.vue';
    import FAQSpamQuestion from './FAQSpamQuestion.vue';

    export default {
        components: {
            FAQQuestion,
            FAQSpamQuestion
        },
        data() {
            return {

            };
        },
        props: {
            target: String
        },
        methods: {
        }
    };
</script>
<style scoped>
    .badge {
        height: 24px;
    }
</style>