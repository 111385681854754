<template>
    <article class="blog-post">
        <h1 class="title">{{ post.title }}</h1>
        <div class="info">
            <span class="author">By {{ post.author.displayName }}</span> |
            <span class="date">{{ formatDate(post.updated) }}</span>
        </div>
        <div :class="'body ' + state" v-html="post.content"></div>
        <a class="toggle" href="#" @click.prevent="toggleMore">{{ moreText }}</a>
    </article>
</template>
<script>

    export default {
        components: {
        },
        props: {
            post: Object
        },
        computed: {
            state() {
                if (this.showMore)
                    return "";
                return "collapsed";
            },
            moreText() {
                if (this.showMore) {
                    return "less";
                }
                return "more";
            }
        },
        methods: {
            formatDate(date) {
                const dt = new Date(date);
                return dt.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });

            },
            toggleMore() {
                this.showMore = !this.showMore;
            }
        },
        data() {
            return {
                showMore: false
            };
        },
    };
</script>
<style>

    .blog-post {
        margin: 1em;
        padding: 2em;
        border: solid 1px #ddd;
        border-radius: 13px;
    }

        .blog-post .info {
            color: #555;
            font-size: 0.8em;
        }

            .blog-post .info .author {
            }

            .blog-post .info .date {
            }

        .blog-post a.toggle,
        .blog-post a.toggle:visited,
        .blog-post a.toggle:active,
        .blog-post a.toggle:hover {
            color: #133053;
            text-decoration: underline;
            display: block;
            margin-top: 0.5em;
        }

            .blog-post a.toggle:hover {
                text-decoration: none;
            }

            .blog-post img {
                float: left;
                max-height: 100px;
                margin-right: 1em;
            }

        .blog-post .body.collapsed {
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 100px;
            width: auto;
            height: auto;
        }

        .blog-post .body img {
            max-height: initial;
        }

        .blog-post .body.collapsed img {
            max-height: 100px;
        }
</style>