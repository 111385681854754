<template>
    <section class="hero-section-small">
        <div class="hero">
            <PageHeader />
        </div>
    </section>
</template>
<script>
    import PageHeader from './PageHeader.vue'

    export default {
        components: {
            PageHeader
        },
        data() {
            return {

            };
        }
    };
</script>
<style scoped>
    .hero-section-small {
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .hero {
        padding: 0;
        margin: 0;
        background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("~@/assets/sub-banner.png");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: cover;
        width: 100%;
        height: 300px;
        margin-bottom: 2em;
    }

        .hero .content {
            flex: 1;
            align-content: center;
            padding: 0 10em 5em;
        }

            .hero .content p {
                margin-bottom: 2em;
                width: 569px;
                max-width: 100%;
                font-size: 1em;
                line-height: 1.7em;
                letter-spacing: -0.04em;
            }



    @media (max-width: 1440px) {
        .hero .content {
            padding: 3em;
            padding-top: 1em;
        }

            .hero .content h1 {
                font-size: 1.5em;
            }

            .hero .content p {
                width: 450px;
                font-size: 0.9em;
                margin-bottom: 1em;
            }
    }

    @media (max-width: 769px) {
        .hero {
            height: 150px;
        }
    }

    @media (max-width: 600px) {
        .hero .content {
            flex: 100%;
            align-content: center;
            padding: 2em;
        }

            .hero .content button {
                max-width: 45%;
            }
    }

    @media (max-width: 450px) {
        .hero .content button {
            font-size: 0.8em;
            max-width: 40%;
        }
    }
</style>