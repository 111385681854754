<template>
    <div class="get-started-item grid-row">
        <div class="grid-column col-1">
            <h3><strong>STEP {{Step}}:</strong> {{Title}}</h3>
            <div class="text">
                <slot>
                </slot>
            </div>
        </div>
        <div :class="'grid-column col-2 get-started-icon-col' + lastCSS" >
            <div class="get-started-step">
                {{Step}}
            </div>
            <div class="get-started-line"></div>
        </div>
        <div class="grid-column col-3">
            <img :alt="altText" :src="ImageUrl" />
        </div>
    </div>
</template>
<script>
    export default {
        components: {
        },
        props: {
            Step: Number,
            Title: String,
            ImageUrl: String,
            IsLast: Boolean
        },
        computed: {
            altText() {
                return `Step ${this.Step}: ${this.Title}`;
            },
            lastCSS() {
                if (this.IsLast)
                    return " get-started-last";
                return "";
            }
        },
        data: function () {
            return {
            };
        }
    };
</script>
<style>
    .get-started-item h3 {
        color: #000;
        font-weight: bold;
    }

    .grid-row {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 150px 1fr;
        grid-template-rows: 1fr;
        gap: 1em;
        clear: both;
        align-items: end;
    }

    .grid-column.col-1 .text {
        min-height: 309px;
        background-color: #F5F5F5;
        border-radius: 20px;
        padding: 1em;
        font-size: 0.9em;
        box-sizing: border-box;
    }

        .grid-column.col-1 .text p {
            
            margin: 0;
            margin-bottom: 1em;
        }

            .grid-column.col-1 .text p:last-child {
                margin: 0;
            }

            .grid-column.col-2 {
                height: 100%;
            }

        .get-started-step {
            position: relative;
            top: 50%;
            margin: 0 auto;
            line-height: 50px;
            text-align: center;
            font-weight: bold;
            width: 50px;
            height: 50px;
            background-image: url("~@/assets/icons/step-icon.svg");
            background-size: 100% 100%;
        }

    .grid-column .get-started-line {
        position: relative;
        height: 100%;
        top: 50%;
        left: 50%;
        content: '';
        border-left: dashed 3px #e67737;
    }

    .get-started-last .get-started-line {
        display: none;
    }



    @media (max-width: 1065px) {
        .grid-row {
            grid-template-columns: 1fr 100px 1fr;
        }

        .grid-column.col-1 .text {
            min-height: auto;
            font-size: 0.7em;
        }
    }

    @media (max-width: 700px) {
        .grid-row .col-2 {
            display: none;
        }

        .grid-row .col-3 {
            text-align: center;
        }
    

            .grid-row {
                grid-template-columns: 1fr;
            }

        .grid-column.col-1 .text {
            min-height: auto;
            font-size: 0.9em;
        }
    }

</style>