<template>
    <div class="view">
        <PageHero />
        <section class="fb">
            <div class="flex-container">
                <div class="col">
                </div>
                <div class="col">
                    <h3>Your Facebook Data</h3>
                    <h1>How to Delete <b>Your Facebook Data</b></h1>
                    <p>Gravitii allows you to login to the Gravitii platform using your Facebook login.  This simplifies the registration process and allows you to access the Gravitii platform for finding or providing home care services.</p>
                    <p>Facebook shares your name and email address to assist in creating your account profile. If you would like to delete this information, 
                    please login and navigate to your profile.  Select the security tab and click or tap the Delete button under "Delete User". If you have any issues please reach out to <a href="mailto:support@gravitii.care">support@gravitii.care</a></p>

                    <h2>How to remove Gravitii.care from Your Facebook Account</h2>
                    <ol>
                        <li>Go to your Facebook account's Setting & Privacy. Click "Settings".</li>
                        <li>Look for "Apps and Websites" and you will see all the apps and websites you linked with your Facebook account.</li>
                        <li>Search and then click "Gravitii.care".</li>
                        <li>Click "Remove".</li>
                        <li>You have successfully removed the Gravitii.care app from connecting to your Facebook profile.</li>
                    </ol>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import PageHero from '../components/PageHeroSmall.vue';

    let meta = {
        type: "website",
        title: "Gravitii - Your Facebook Data",
        description: "Gravitii modernizes the process of accessing care by leveraging technology to help deliver a more personalized, effective and timely home care experience for you or your loved ones.",
        image: window.location.origin + "/feature.jpg"
    };


    export default {
        name: "FacebookDataDeletionView",
        components: {
            PageHero
        },
        data() {
            return {

            };
        },
        metaInfo() {
            return {
                title: meta.title,
                meta: [
                    { name: 'description', content: meta.description },

                    { property: 'og:url', content: window.location.origin },
                    { property: 'og:type', content: meta.type },
                    { property: 'og:title', content: meta.title },
                    { property: 'og:description', content: meta.description },
                    { property: 'og:image', content: meta.image },

                    { property: 'twitter:url', content: window.location.origin },
                    { property: 'twitter:type', content: meta.type },
                    { property: 'twitter:title', content: meta.title },
                    { property: 'twitter:description', content: meta.description },
                    { property: 'twitter:image', content: meta.image },
                ]
            }
        }
    };
</script>
<style>
    .fb .flex-container {
        display: flex;
        flex-direction: row;
        padding: 4em;
    }

        .blog .flex-container.col {
            flex: 50%;
        }

</style>