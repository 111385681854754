<template>
    <section class=" badges">
        <a class="chamber" href="https://secure.kelownachamber.org/Senior-Services/Gravitiicare-13600" target="_blank">
            <img src="@/assets/kelowna-chamber-proud-member.png" alt="Kelowna Chamber Proud Member" />
        </a>
        <a class="vbb" href="https://www.canadianlegacy.org/" target="_blank">
            <img src="@/assets/vbb.png" style="border: 0;" alt="Certified VBB Business - Canadian Legacy Project" />
        </a>
        <a class="bbb" href="https://www.bbb.org/ca/bc/kelowna/profile/information-technology-services/gravitii-technologies-0037-2430757/#sealclick" target="_blank" rel="nofollow">
            <img src="https://seal-mbc.bbb.org/seals/blue-seal-293-61-bbb-2430757.png" style="border: 0;" alt="Gravitii Technologies BBB Business Review" />
        </a>
    </section>
</template>
<script>


    export default {
        components: {
        },
        data: function () {
            return {
            };
        },
        methods: {
        }
    };
</script>
<style>
    section.badges {
        display: flex;
        width: 1150px;
        max-width: 100%;
        margin: 0 auto;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
    }

        section.badges .bbb > img {
            height: 50px;
        }

        section.badges .vbb > img {
            height: 120px;
        }

        section.badges .chamber > img {
            height: 100px;
            border-radius: 15px;
        }

        section.badges .flex-container {
            display: flex;
            flex-direction: row;
            padding: 4em;
        }

    @media (max-width: 900px) {
        section.badges .bbb > img {
            height: 30px;
        }

        section.badges .vbb > img {
            height: 80px;
        }

        section.badges .chamber > img {
            height: 50px;
            border-radius: 15px;
        }
    }

    @media (max-width: 500px) {
        section.badges {
            flex-wrap: wrap;
        }

            section .badges .bbb > img {
                height: 30px;
            }

            section.badges .vbb > img {
                height: 80px;
            }

            section.badges .chamber > img {
                height: 50px;
                border-radius: 15px;
            }
    }
</style>