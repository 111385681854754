<template>
    <span class="view">
        <PageHero />
        <EventRegistration list="workshop_june"></EventRegistration>
    </span>
</template>

<script>
    import PageHero from '../components/PageHeroSmall.vue';
    import EventRegistration from "../components/events/EventRegistration.vue";

    let meta = {
        type: "website",
        title: "Attention registered health care aides & students!",
        description: "Hear from insightful speakers, connect with industry peers and discover the innovative solutions Gravitii has to offer you.",
        image: window.location.origin + "/posts/2023/06/workshop/event.jpg"
    };

    export default {
        name: "EventsView",
        components: {
            PageHero,
            EventRegistration
        },
        data() {
            return {
                
            };
        },
        metaInfo() {
            return {
                title: meta.title,
                meta: [
                    { name: 'description', content: meta.description },

                    { property: 'og:url', content: window.location.origin },
                    { property: 'og:type', content: meta.type },
                    { property: 'og:title', content: meta.title },
                    { property: 'og:description', content: meta.description },
                    { property: 'og:image', content: meta.image },

                    { property: 'twitter:url', content: window.location.origin },
                    { property: 'twitter:type', content: meta.type },
                    { property: 'twitter:title', content: meta.title },
                    { property: 'twitter:description', content: meta.description },
                    { property: 'twitter:image', content: meta.image },
                ]
            }
        }
    };
</script>
<style>
</style>