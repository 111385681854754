<template>
    <section class="padded">
        <div class="flex-container">
            <h3>Frequently Asked Questions</h3>
            <h1><b>Looking</b> for <b>answers</b>?  Find them here...</h1>
            <br />
            <AppButton class="toggle" :class="mode == 'patient' ? 'selected' : ''" @click="mode='patient'">Get Care</AppButton>
            &nbsp;
            <AppButton class="toggle" :class="mode == 'provider' ? 'selected' : ''" @click="mode='provider'">Give Care</AppButton>
            <br />
            <br />
            <FAQProvider v-if="mode=='provider'" :target="target"></FAQProvider>
            <FAQClient v-if="mode=='patient'" :target="target"></FAQClient>

            <FAQMore></FAQMore>
        </div>
    </section>
</template>
<script>
    import AppButton from '../AppButton.vue';
    import FAQMore from './FAQMore.vue';
    import FAQProvider from './FAQProvider.vue';
    import FAQClient from './FAQClient.vue';

    export default {
        components: {
            AppButton,
            FAQMore,
            FAQClient,
            FAQProvider
        },
        data() {
            return {
                mode: "patient",
                target: ""
            };
        },
        mounted() {
            console.log("who=", this.$route.params.who, this.$route.params);
            if (this.$route.params.who == 'provider') {
                this.mode = "provider";
            } else {
                this.mode = "patient";
            }

            if (this.$route.params.id != "")
                this.target = this.$route.params.id;
        },
    };
</script>
<style scoped>
    .toggle.button {
        margin-top: 1em;
    }
</style>