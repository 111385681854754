<template>
    <div class="header">
        <AnnouncementHeader v-if="showAnnoucement" @click="onCloseAnnouncement"></AnnouncementHeader>
        <div class="contact">
            <div class="phone">
                Call us <a href="tel:17789460075">1-778-946-0075</a>
            </div>
        </div>
        <div class="flex">
            <div class="col-logo">
                <gLogo class="logo" />
            </div>
            <div class="col-menu">
                <gMenu class="menu" />
            </div>
            <div class="col-platform">
                <PlatformActions class="header-actions"></PlatformActions>
            </div>
        </div>
    </div>
</template>
<script>
    import AppLogo from './AppLogo.vue'
    import AppMenu from './AppMenu.vue'
    import PlatformActions from './PlatformActions.vue';
    import AnnouncementHeader from './AnnouncementHeader.vue';

    let hideAnnoucementForSession = sessionStorage.getItem("hideAnnoucementForSession") || false;

    export default {
        components: {
            gLogo: AppLogo,
            gMenu: AppMenu,
            PlatformActions: PlatformActions,
            AnnouncementHeader: AnnouncementHeader
        },
        data() {
            return {
                showAnnoucement: false && !hideAnnoucementForSession
            };
        },
        methods: {
            buttonClicked: function () {
                alert("coming soon");
            },
            onCloseAnnouncement() {
                this.showAnnoucement = false;
                sessionStorage.setItem("hideAnnoucementForSession", true);
            },
        }
    };
</script>
<style scoped>
    .header {
        padding: 0;
        margin: 0;
    }

        .header .contact {
            position: fixed;
            bottom: 0;
            display: flex;
            flex-direction: row-reverse;
            box-sizing: border-box;
            width: 100%;
            max-width: 1919px;
            align-items: flex-end;
            text-align: right;
            padding: 0em 1em 0 0;
            margin: 0;
            z-index: 99999;
        }



            .header .contact .phone {
                float: right;
                padding: 0.5em;
                text-align: center;
                width: 250px;
                color: #fff;
                background: #e67737;
                border-radius: 14px 14px 0 0;
            }

                .header .contact .phone a {
                    font-family: inherit;
                    width: 100%;
                    color: #fff;
                    font-size: 1.1em;
                    font-weight: bold;
                    text-decoration: none;
                }

                    .header .contact .phone a:hover {
                        text-decoration: underline;
                    }


        .header .flex {
            display: flex;
            gap: 2em;
            align-items: center;
            padding: 0;
            clear: both;
        }

        .header .break {
            height: 0;
        }

        .header .col-logo {
            width: 20em;
            flex: 0 0 20em;
        }

            .header .col-logo .logo {
                width: 100%;
            }

        .header .col-menu {
            flex: 1 1 auto;
        }


    @media (max-width: 1440px) {
        .header .col-logo {
            width: 15em;
            flex: 0 0 15em;
        }
    }

    @media (max-width: 1110px) {
        .header .flex {
            gap: 0;
            flex-flow: wrap-reverse;
            justify-content: flex-end;
        }

        .header .col-logo {
            width: 10em;
            flex: 0 0 10em;
        }
    }

    @media (max-width: 845px) {
        .header {
            gap: 0;
        }

        .col-platform {
            display: none;
        }
    }
</style>