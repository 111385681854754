<template>
    <kButton @click.prevent="clickHandler" class="button">
        <slot>Button</slot>
    </kButton>
</template>
<script>
    import { Button } from '@progress/kendo-vue-buttons'

    export default {
        components: {
            kButton: Button
        },
        data() {
            return {

            };
        },
        methods: {
            clickHandler: function (e) {
                this.$emit("click", e);
            }
        }
    };
</script>
<style scoped>
    .toggle.button {
        color: #133053;
        background: #FFF;
    }

        .toggle.button.selected {
            color: #D9D9D9;
            background: #133053;
        }

    .button.k-button {
        font-family: 'Montserrat';
        font-weight: bold;
        text-transform: unset;
        letter-spacing: 1pt;
    }

    .button {
        font-weight: 600;
        font-size: 18px;
        line-height: 40px;
        letter-spacing: -0.03em;
        color: #D9D9D9;
        background: #133053;
        border: solid 1px #133053;
        border-radius: 30px;
        justify-content: center;
        align-items: center;
        padding: 15px 40px;
        gap: 10px;
        width: 163px;
        width: auto;
        height: 54px;
    }

        .button:disabled {
            background-color: #aaa;
            border: solid 1px #aaa;
        }

    @media (max-width: 1193px) {
        .button {
            padding: 15px 20px;
            /*width: 125px;*/
            height: 40px;
        }
    }
</style>