<template>
    <div class="menu">
        <kMenu class="header-menu" @select="onSelect" :items="items" />
        <kButton class="hamburger" :icon="'menu'" :fill-mode="'flat'" @click="toggleDrawer" aria-label="Menu"></kButton>
        <kDrawer class="header-drawer" :mini="false" position="end" mode="overlay" :items="drawerItems" :expanded="expanded" @select="selectDrawerItem" @overlayclick="closeDrawer" />
    </div>
</template>
<script>
    import menuItemData from '@/data/menu.json';
    import { Menu, Drawer } from "@progress/kendo-vue-layout";
    import { Button } from "@progress/kendo-vue-buttons";

    let expanded = false;

    export default {
        components: {
            "kMenu": Menu,
            "kDrawer": Drawer,
            "kButton": Button
        },
        data: function () {
            return {
                items: menuItemData,
                expanded
            }
        },
        computed: {
            drawerItems() {
                let items = [];
                for (var i = 0; i < this.items.length; i++) {
                    var item = this.items[i];
                    if (item.items) {
                        items = items.concat(item.items);
                    } else {
                        items.push(item);
                    }
                }

                items = items.concat([
                    {
                        "separator": true
                    },
                    {
                        "text": "Login",
                        "url": "https://portal.gravitii.care/?action=login",
                        "target": "link",
                        "icon": "k-i-launch"
                    },
                    {
                        "text": "Register",
                        "url": "https://portal.gravitii.care/?action=register",
                        "target": "link",
                        "icon": "k-i-launch"
                    }]
                );
                return items;
            }
        },
        methods: {
            openDrawer: function () {
                this.expanded = true;
            },
            closeDrawer: function () {
                this.expanded = false;
            },
            toggleDrawer: function () {
                this.expanded = !this.expanded;
            },
            selectDrawerItem: function (e) {
                event.preventDefault();
                if (e.itemIndex >= 0 && e.itemIndex < e.component.items.length) {
                    let item = e.component.items[e.itemIndex];
                    console.log(item);
                    if (item) {
                        if (item.target === "external") {
                            window.open(item.url, "_blank");
                        } else if (item.target === "link") {
                            window.location.href = item.url;
                        } else {
                            this.$router.push(item.url);
                        }
                    }
                }
                this.closeDrawer();
            },
            onSelect: function (e) {
                event.preventDefault();
                if (e.item.target === "external") {
                    window.open(e.item.url, "_blank");
                } else {
                    this.$router.push(e.item.url);
                }
                return true;
            },
        }
    };
</script>
<style>
    .menu .hamburger {
        display: none;
        width: 40px;
        height: 40px;
        margin-right: 1em;
    }
     
    .k-menu-popup,
    .k-popup {
        background: transparent;
    }

    .k-menu-popup .k-menu-group {
        background-color: rgba(0, 0, 0, 0.0696);
        padding: 0.5em;
    }

    .k-item.k-focus,
    .k-menu .k-item:hover,
    .k-menu .k-item.k-hover,
    .k-menu .k-item.k-selected{
        border-radius: 15px;
        background-color: transparent;
    }


    .k-menu-link-text {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.03em;
        color: #133053;
    }

    @media (max-width:842px) {
        .menu .header-menu {
            display: none;
        }

        .menu {
            align-items: flex-end;
            text-align: right;
        }

            .menu .hamburger {
                float: right;
                display: block;
            }
    }
</style>