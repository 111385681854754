<template>
    <Window class="newsletter-window"
            v-if="visible"
            :title="title"
            :initial-width="width"
            :initial-height="height"
            :width="width"
            :height="height"
            :maximize-button="() => null"
            :minimize-button="() => null"
            @close="onClose">
        <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <h3>Add me to the {{ listname }} list</h3>
                <div class="desc">{{ description }}</div>
                <div class="field">
                    <ValidationProvider name="First name" :rules='{ required: nameRequired }' v-slot="{ errors }">
                        <label for="firstname">
                            First name <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                        </label>
                        <kInput id="firstname" v-model="firstname" type="text" :placeholder='listname == "investors" ? "" : "(optional)"' />
                    </ValidationProvider>
                </div>
                <div class="field">
                    <ValidationProvider name="First name" :rules='{ required: nameRequired }' v-slot="{ errors }">
                        <label for="lastname">
                            Last name <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                        </label>
                        <kInput id="lastname" v-model="lastname" type="text" :rules='{ required: listname === "investors" }' :placeholder='listname == "investors" ? "" : "(optional)"' />
                    </ValidationProvider>
                </div>
                <div class="field">
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                        <label for="email">
                            Email <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                        </label>
                        <kInput id="email" v-model="email" type="email" placeholder="you@domain.com" />

                    </ValidationProvider>
                </div>
                <div v-if='listname != "investors"' class="field">
                    <ValidationProvider name="Postal Code" rules="required|postal_code" v-slot="{ errors }">
                        <label for="postal_code">
                            Postal Code <span v-if="errors[0]" class="err"> ({{ errors[0] }})</span>
                        </label>
                        <kInput id="postal_code" v-model="postal_code" type="text" placeholder="A0A 0A0" />

                    </ValidationProvider>
                </div>
                <div class="buttons">
                    <gButton id="submit" @click.prevent="handleSubmit(onSubmit)" :disabled='sending||invalid'>Submit</gButton>
                    <gButton id="cancel" @click="onClose">Cancel</gButton>
                </div>
                <Transition>
                    <span v-show="showSuccess" class="success">Your message has been sent!</span>
                </Transition>
            </form>
        </ValidationObserver>
    </Window>
</template>
<script>
    import AppButton from './AppButton.vue';
    import { Window } from '@progress/kendo-vue-dialogs';
    import { Input } from '@progress/kendo-vue-inputs';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { extend } from 'vee-validate';

    extend('postal_code', {
        validate: value => {
            var regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            return regex.test(value);
        },
        message: "Postal Code must in the format A0A 0A0"
    });

    let lists = {
        patient: {
            id: "4c64b5b8-d26d-4339-82dd-cff0aa282067",
            name: "patient information",
            title: "Sign up to receive patient related updates",
            description: "The Gravitii platform is currently under development.  We are working hard to get the platform ready to roll out in regions across Canada.  We appreciate your interest in using Gravitii to help serve your home care needs.  Please enter you Email address and postal code (your first and last name are optional) and we will keep you updated as our platform rolls out in your area."
        },
        provider: {
            id: "fc29c4af-cef3-4e21-8fb0-892aba9d795d",
            name: "care provider information",
            title: "Sign up to receive care provider related updates",
            description: "The Gravitii platform is currently under development.  We are working hard to get the platform ready to roll out in regions across Canada.  We appreciate your interest in using Gravitii to help run your home care business.  Please enter you Email address and postal code (your first and last name are optional) and we will keep you updated as our platform rolls out in your area."
        },
        investors: {
            id: "e6a7c826-12cd-4153-b91c-59902893b0f2",
            name: "investors",
            title: "Are you interested in investing?",
            description: "We are not actively seeking investors at this this.  However, please sign up to recieve information about future investment opportunities."
        },
        newsletter: {
            id: "2f1d971d-8c70-4aae-9d85-d2b9950135d7",
            name: "Gravitii Newsletter",
            title: "Sign up to receive the Gravitii newsletter",
            description: "We are passionate about support home care in Canada.  Sign up to recieve updates from Gravitii.  We promise not to overwhelm you with content!"
        }
    };

    let showSuccess = false;
    let invalid = false;
    let sending = false;
    let firstname = "";
    let lastname = "";
    let email = "";
    let postal_code = "";
    let width = 800;
    let height = 800;

    export default {
        components: {
            gButton: AppButton,
            ValidationObserver,
            ValidationProvider,
            Window,
            kInput: Input
        },
        props: {
            list: String,
            visible: Boolean
        },
        data: function () {
            return {
                lists,
                showSuccess,
                invalid,
                sending,
                firstname,
                lastname,
                email,
                postal_code,
                width,
                height

            };
        },
        computed: {
            selectedList: function () {
                return this.lists[this.list] || { id: "", name: "", title: "", description: "" };
            },
            listname: function () {
                return this.selectedList.name;
            },
            title: function () {
                return this.selectedList.title;
            },
            description: function () {
                return this.selectedList.description;
            },
            nameRequired: function () {
                return this.listname === "investors";
            }
        },
        mounted() {
            window.addEventListener('resize', this.onWindowResize);
            window.addEventListener('load', this.onWindowLoad);
        },
        unmounted() {
            window.removeEventListener('resize', this.onWindowResize);
            window.addEventListener('load', this.onWindowLoad);
        },
        methods: {
            onWindowLoad() {
                this.resizeWindow();
            },
            onWindowResize() {
                this.resizeWindow();
            },
            onClose() {
                this.$emit("close");
            },
            onSubmit: function () {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return;
                    }

                    // Prevent multiple clicks.
                    if (this.sending)
                        return;

                    this.sending = true;

                    let url = this.appConfig.$api_url + "/contact/list/" + this.selectedList.id;

                    let msg = {
                        first_name: this.firstname,
                        last_name: this.lastname,
                        email: this.email,
                        postal_code: this.postal_code
                    };

                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(msg)
                    };

                    fetch(url, requestOptions)
                        .then(() => {
                            this.showSuccess = true;
                            this.firstname = "";
                            this.lastname = "";
                            this.email = "";
                            this.postal_code = "";
                            this.message = "";
                            this.sending = false;
                            this.$nextTick(() => {
                                this.$refs.form.reset();
                                this.onClose();
                            });
                        });
                });
            },
            resizeWindow() {
                let width = document.documentElement.clientWidth;
                if (width < 800) {
                    this.width = document.documentElement.clientWidth;
                    this.height = document.documentElement.clientHeight;
                } else {
                    this.width = 800;
                    this.height = 800;
                }
            }
        }
    };
</script>
<style scoped>
    .newsletter-window .buttons {
        
        box-sizing: border-box;
        bottom: 25px;
        right: 25px;
        text-align: right;
    }

    .newsletter-window .desc {
        font-size: 0.9em;
        border: solid 1px #ddd;
        padding: 1em;
        border-radius: 5px;
        margin: 1em 0 1em 0;
        background-color: #fefefe;
    }

    .newsletter-window .field {
        margin-bottom: 1em;
    }

        .newsletter-window .field .err {
            display: inline;
            color: #f00;
            font-size: 0.8em;
        }

        .newsletter-window .field .k-input {
            height: 3em;
            line-height: 1.9em;
        }

    .newsletter-window .buttons button {
        width: 100px;
        height: 1em;
        font-size: 0.8em;
        line-height: 1em;
        border-radius: 20px;
        margin-right: 0.6em;
    }


    .newsletter-window .k-window[style] {
        width: 100%;
        height: 100%;
    }
</style>