<template>
    <section class="view">
        <PageHero />
        <section class="padded">
            
            <div class="careers-none">
                <h1>Careers at Gravitii</h1>
                <p>We are not currently hiring.  If you would like to send us your resume you can send it to <a href="mailto:careers@gravitii.care">careers@gravitii.care</a> and we will keep it on file for future openings.</p>

            </div>
        </section>
        <section v-if="false" class="padded">
            <h1>Position Title: Wizard of light bulb moments - Marketing Specialist</h1>
            <p><b>Location:</b> Kelowna, BC (Remote/Work from Home)</p>
            <p><b>Salary Range:</b> $60,000 - $75,000 CAD annually</p>
            <h1>Description</h1>
            <p>The wizard of light bulb moments is an individual with a non-traditional set of skills who excels at the art of marketing.  This individual will become an expert at creating value from simple, inexpensive ingredients.  They will be creative and thoughtful in their pursuit of excellence while learning from and surpassing expert knowledge and experience. This person will have the drive and raw talent to become the driving force behind the Gravitii marketing engine.</p>
            <h1>Responsibilities</h1>
            <ul>
                <li>Manage all marketing activities in a mostly business to consumer (B2C) marketplace.</li>
                <li>Collaborate with mentors to develop personal skills and experience.</li>
                <li>Review industry data and Gravitii data to establish impactful KPIs.</li>
                <li>Develop, document, and report on LTV, CAC, and Churn.</li>
                <li>Create a reporting process and dashboards to share KPIs.</li>
                <li>Review available data to determine key pipeline events.</li>
                <li>Establish and continuously tune the sales funnel from existing data.</li>
                <li>Review and update buyer personas, understand pain points, needs, and preferences.</li>
                <li>Conduct market research to understand trends, opportunities, threats, and the competitive landscape.</li>
                <li>Review and refine the value proposition and pricing model.</li>
                <li>Implement a lead generation strategy and identify effective sales channels.</li>
                <li>Create sales collateral, pitch decks, and a comprehensive marketing plan.</li>
                <li>Develop relationships with BC media, publish content, and manage social media processes and publishing schedules.</li>
            </ul>
            <h1>Requirements</h1>
            <ul>
                <li>A great personality, strong communication skills, and a sense of humour is a must!</li>
                <li>You must be a Canadian Citizen or Permanent Resident and legally allowed to work in Canada.</li>
                <li>Ability to work on your own and in a team-based environment.</li>
                <li>Comfortable working in a startup environment</li>
                <li>Experience managing multiple projects and adhering to deadlines.</li>
                <li>Clear copywriting and editing skills.</li>
                <li>Strong organizational, communication, and customer service skills.</li>
                <li>Strong problem solving and collaboration skills.</li>
                <li>Proficiency in Microsoft Office</li>
                <li>Proficiency in Graphic Design</li>
                <li>High School Diploma or equivalent experience. Post Secondary Diploma or Degree is a plus but is not required.</li>
                <li>Experience with Google paid search and social media advertising is a plus but not a strict requirement.</li>
            </ul>

            <h1>About Gravitii</h1>
            <p>Gravitii Technologies was founded with the vision of transforming the home care industry by bridging the gap between patients seeking quality care and care providers looking for better work opportunities. The company was established by Jeff Van Dyk, a serial entrepreneur with a strong technology background, and Dr. Kevin Wade, a palliative care physician. Recognizing the underserved nature of the home health care market and the evolving needs of aging populations, we embarked on creating a platform that will revolutionize the way home care is delivered.</p>
            <p>The technology uses advanced matching algorithms to match the patient to the ideal care provider using a combination of proximity, availability, specific care needs, patient, and care provider preferences. Business tools are offered to help care providers run their businesses, such as payment processing, scheduling, and communication tools. The software works in the background, removing the friction that exists in the current system and improving the experience for both the patient and care provider.</p>
            <p>The Gravitii platform will follow the gig-based model that has been popularized by companies like Uber, Airbnb, and Skip the Dishes.  By doing this we aim to better meet the needs of the patient by using technology to match these needs to available skills. This will ultimately provide a more efficient and better experience for the patient.  In addition, by providing more flexibility and autonomy to the care providers we anticipate that this will draw more care providers into the system so that we can start to address the ongoing shortage of care providers.</p>

            <p style="font-weight: bold; font-size: 1.2em;">To apply, please send your resume and cover letter to <a href="mailto:careers@gravitii.care">careers@gravitii.care</a></p>
        </section>
    </section>
</template>

<script>
    import PageHero from '../components/PageHeroSmall.vue';

    let meta = {
        type: "website",
        title: "Gravitii - Careers - Redefining home care... one connection at a time",
        description: "Gravitii modernizes the process of accessing care by leveraging technology to help deliver a more personalized, effective and timely home care experience for you or your loved ones.",
        image: window.location.origin + "/feature.jpg"
    };

    export default {
        name: "CareersView",
        components: {
            PageHero
        },
        data() {
            return {

            };
        },
        metaInfo() {
            return {
                title: meta.title,
                meta: [
                    { name: 'description', content: meta.description },

                    { property: 'og:url', content: window.location.origin },
                    { property: 'og:type', content: meta.type },
                    { property: 'og:title', content: meta.title },
                    { property: 'og:description', content: meta.description },
                    { property: 'og:image', content: meta.image },

                    { property: 'twitter:url', content: window.location.origin },
                    { property: 'twitter:type', content: meta.type },
                    { property: 'twitter:title', content: meta.title },
                    { property: 'twitter:description', content: meta.description },
                    { property: 'twitter:image', content: meta.image },
                ]
            }
        }
    };
</script>
<style>
    .careers-none {
        border: solid 1px #ddd;
        border-radius: 15px;
        background-color: #fafafa;
        margin: 3em;
        padding: 2em;
    }

    @media (max-width: 600px) {
        .careers-none {
            margin: 0;
        }
    }
</style>