<template>
    <div class="announcement">
        <span title="Announcement" class="k-icon k-font-icon k-icon-xl k-i-bell"></span>
        Now available in Central Okanagan and Greater Victoria!  Limited availability throughout other areas in British Columbia.
        <span @click="onclick" title="Close" class="close-icon k-icon k-font-icon k-icon k-i-x"></span>
    </div>
</template>
<script>

    export default {
        data() {
            return {

            };
        },
        mounted() {
            document.body.classList.add('announcing');
        },
        destroyed() {
            document.body.classList.remove('announcing')
        },
        methods: {
            onclick: function () {
                this.$emit("click");
            }
        }
    };
</script>
<style>
    .announcement {
        z-index: 1;
        padding: 1.5em;
        box-sizing: border-box;
        height: 80px;
        color: #fff;
        position: fixed;
        top: 0;
        width: 100%;
        max-width: 1916px;
        background-color: #e67737;
    }

        .announcement .close-icon {
            float: right;
        }

    body.announcing .view {
        margin-top: 80px;
    }

    @media (max-width: 1093px) {
        .announcement {
            height: 100px;
        }

        body.announcing .view {
            margin-top: 100px;
        }
    }

    @media (max-width: 585px) {
        .announcement {
            height: 120px;
        }

        body.announcing .view {
            margin-top: 120px;
        }
    }

    @media (max-width: 523px) {
        .announcement {
            height: 150px;
        }

        body.announcing .view {
            margin-top: 150px;
        }
    }
</style>
