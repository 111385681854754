<template>
    <AppButton @click="onclick" class="icon">
        <slot>Learn More <span class="k-icon k-font-icon k-icon-xl k-i-question"></span></slot>
    </AppButton>
</template>
<script>
    import AppButton from '../AppButton.vue';

    export default {
        components: {
            AppButton,
        },
        data() {
            return {
            };
        },
        props: {
            who: {
                type: String,
                default: 'patient',
            },
        },
        methods: {
            onclick: function () {
                if (this.who == 'provider') {
                    this.$router.push("/faq/provider");
                } else {
                    this.$router.push("/faq/patient");
                }
            }
        }
    };
</script>
<style scoped>
    .icon.button {
        color: #133053;
        opacity: 0.8;
        background: #fff;
        font-weight: normal;
    }

    @media (max-width: 1193px) {
        .icon.button {
            width: 150px;
            margin: 0.5em 0.5em 0 0;
            font-size: 0.8em;
        }
    }
</style>