<template>
    <div class="view jfs-landing-page">
        <section>
            <div class="flex-container">
                <PageHero id="home"  signUpText="Sign up now" :heroImage="require('@/assets/jfs-banner.png')">
                    <h1>
                        Redefining home care...<br />
                        <strong>one connection at a time</strong>.
                    </h1>
                    <p>
                        Take your home care experience into your own hands. Signing up with Gravitii is <b>free</b>, and you only pay after you receive care.
                    </p>
                </PageHero>
                <SectionBadges id="badges" />
                <SectionCoreFeatures id="corefeatures" ButtonText="Find Care">
                    <template #header>
                        <h1>We connect you with <strong>professional, affordable, and reliable home care.</strong></h1>
                    </template>
                    <template #columns>
                        <div class="col">
                            <div class="icon"><img alt="Your Care, Your Choice" src="@/assets/icons/female-happy-face.svg" /></div>
                            <h4>Your Care, Your Choice</h4>
                            <div>
                                Handpick your ideal care provider from a network of skilled professionals.
                                <b>No contracts, no strings attached. Just care that fits your needs, on your terms.</b>
                            </div>
                        </div>
                        <div class="col">
                            <div alt="Quality Care, Affordable Service" class="icon"><img src="@/assets/icons/pointer.svg" /></div>
                            <h4>Quality Care, Affordable Service</h4>
                            <div>
                                Book care that suits your budget. Gravitii connects you with quality care while giving you the flexibility to <b>book what you need, when you need it</b>.
                            </div>
                        </div>
                        <div class="col">
                            <div alt="Your Safety, Our Priority" class="icon"><img src="@/assets/icons/thumbs-up.svg" /></div>
                            <h4>Your Safety, Our Priority</h4>
                            <div>Our platform helps you find professional care providers with <b>verified background checks, ratings and reviews, liability insurance and WCB coverage</b> for added peace of mind.</div>
                        </div>
                    </template>
                </SectionCoreFeatures>
                <SectionGetStarted id="getstarted" />
                <SectionTestimonials id="testimonials" />
                <FAQ />
            </div>
        </section>
    </div>
</template>

<script>
    import PageHero from '../../components/PageHeroCustom.vue';
    import SectionBadges from '../../components/SectionBadges.vue';
    import SectionCoreFeatures from '../../components/SectionCoreFeatures.vue';
    import SectionGetStarted from '../../components/SectionGetStarted.vue';
    import SectionTestimonials from '../../components/SectionTestimonials.vue';
    import FAQ from '../../components/faq/FAQ.vue';

    let meta = {
        type: "website",
        title: "Redefining home care... one connection at a time.",
        description: "Take your home care experience into your own hands. Signing up with Gravitii is free, and you only pay after you receive care.",
        image: window.location.origin + "/jfs/feature.jpg"
    };

    export default {
        name: "jfsLandingPageView",
        components: {
            PageHero,
            SectionBadges,
            SectionCoreFeatures,
            SectionGetStarted,
            SectionTestimonials,
            FAQ
        },
        data() {
            return {

            };
        },
        metaInfo() {
            return {
                title: meta.title,
                meta: [
                    { name: 'description', content: meta.description },

                    { property: 'og:url', content: window.location.origin },
                    { property: 'og:type', content: meta.type },
                    { property: 'og:title', content: meta.title },
                    { property: 'og:description', content: meta.description },
                    { property: 'og:image', content: meta.image },

                    { property: 'twitter:url', content: window.location.origin },
                    { property: 'twitter:type', content: meta.type },
                    { property: 'twitter:title', content: meta.title },
                    { property: 'twitter:description', content: meta.description },
                    { property: 'twitter:image', content: meta.image },
                ]
            }
        }
    };
</script>
<style>
    .jfs-landing-page .hero {
        background-position: bottom;
        min-height: 850px;
    }

    .jfs-landing-page .menu {
        display: none;
    }

    .jfs-landing-page .hero h1 {
        font-size: 55px;
        font-weight: normal;
    }


    @media (max-width: 1440px) {
        .jfs-landing-page .hero .content h1.jfs {
            font-size: 50px;
        }

        .jfs-landing-page .hero .content p {
            font-size: 1em;
        }
    }

    @media (max-width: 700px) {
        .jfs-landing-page .hero .content h1.jfs {
            font-size: 40px;
        }

        .jfs-landing-page .hero .content {
            margin-top: 0;
            height: auto;
        }

            .jfs-landing-page .hero .content .text {
                background: none;
            }

        .jfs-landing-page .hero {
            background-size: contain;
            min-height: 500px;
        }
    }
</style>